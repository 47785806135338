import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupAddon,
  InputGroupButtonDropdown,
} from "reactstrap";
import { Resources } from "scripts/index.ts";

import { getSortedByValue } from "containers/popups/Wallet/components/Overview/index.ts";
import {
  SearchInput,
  SearchInputStyles,
} from "containers/popups/Wallet/components/SearchInput/index.ts";
import { BalanceTabs } from "containers/popups/Wallet/constants.ts";
import { findBonusToken } from "containers/popups/Wallet/helpers.ts";

import { getSupportedLanguages } from "selectors/locale.ts";
import { getAccountCurrencyMetadata, getEosAccount } from "selectors/loginSelectors";
import {
  getIsFiatCurrency,
  getIsHideZero,
  getSettingsFiatCurrency,
  getViewFiat,
} from "selectors/uiSelectors";
import {
  cryptoRatesSelector,
  getWalletBalances,
  getWalletCasinoBalances,
} from "selectors/walletSelectors";

import { changeCurrency } from "actions/currencyActions";
import { setFiatView, setHideZero } from "actions/uiActions";
import { walletLoadBalances } from "actions/walletActions";

import { currencies, currenciesOrderSocial } from "constants/currencies.ts";

import Sum, { BalanceSum } from "components/common/Sum";

import { getSavedCurrency } from "utils/browserUtils";
import { currencyAmountAvailable } from "utils/currencyUtils";

import { ReactComponent as WalletIcon } from "img/header-icons/wallet-icon.svg";

import styles from "./UserBalance.module.scss";

const UserBalance = ({ className }) => {
  const { pathname } = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [tab, setTab] = useState(BalanceTabs.MAIN);
  const currencyMetadata = useSelector(getAccountCurrencyMetadata);
  const eosAccount = useSelector(getEosAccount);
  const rates = useSelector(cryptoRatesSelector);
  const { data: mainBalances, loading: isLoading } = useSelector(getWalletBalances);
  const supportedLanguages = useSelector(getSupportedLanguages).supportedLanguages;

  const dispatch = useDispatch();
  const { data: casinoBalances } = useSelector(getWalletCasinoBalances);
  const isHideZero = useSelector(getIsHideZero);
  const isFiat = useSelector(getIsFiatCurrency);
  const viewFiat = useSelector(getViewFiat);
  const fiatCurrency = useSelector(getSettingsFiatCurrency);
  const history = useHistory();

  const isMainTab = tab === BalanceTabs.MAIN;
  const balances = isMainTab ? mainBalances : casinoBalances;
  let order = currenciesOrderSocial;
  const { name: currency, hasError } = currencyMetadata || {};

  const bonusTokenAvailable =
    mainBalances &&
    casinoBalances &&
    (findBonusToken(mainBalances) || findBonusToken(casinoBalances));

  const balance = balances?.[currency]
    ? currencies[currency].toDisplayValue(balances[currency])
    : 0;

  useEffect(() => {
    eosAccount && dispatch(walletLoadBalances());
  }, [eosAccount]);

  const toggleFiat = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setFiatView(!viewFiat));
    if (!isFiat && !viewFiat && !fiatCurrency) {
      history.replace({ hash: "#currency" });
    }
  };

  const toggleHideZero = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setHideZero(!isHideZero));
  };

  const changeBalanceViaPathname = (isCasino = false) => {
    const tab = isCasino ? BalanceTabs.CASINO : BalanceTabs.MAIN;
    const balances = isCasino ? casinoBalances : mainBalances;
    const selectedCurrency = getSavedCurrency();
    setTab(tab);
    if (isCasino && Object.values(balances).every((value) => !value)) {
      history.push("#wallet/transfer");
    }
    const availableToken = currencyAmountAvailable({
      balances,
      currencies: currenciesOrderSocial,
      selectedCurrency,
    });
    availableToken && dispatch(changeCurrency(availableToken[0]));
  };

  useEffect(() => {
    const prefixRegex = new RegExp(
      `^/(?:${supportedLanguages.map((lang) => lang.replace("-", "\\-")).join("|")})`
    );

    const isCasinoPage = pathname.replace(prefixRegex, "").startsWith("/casino");

    if (isLoading || !casinoBalances || !balances) return;
    if (isCasinoPage && tab !== BalanceTabs.CASINO) {
      changeBalanceViaPathname(isCasinoPage);
    }
    if (!isCasinoPage && tab !== BalanceTabs.MAIN) {
      changeBalanceViaPathname(isCasinoPage);
    }
  }, [pathname, isLoading, casinoBalances, casinoBalances]);

  if (hasError) {
    return <div />;
  }

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  if (searchValue) {
    order = currenciesOrderSocial.filter((token) =>
      token.includes(searchValue.toUpperCase())
    );
  }

  if (isHideZero && rates && balances) {
    order = order.filter((token) => {
      const balance = balances[token] ?? 0;
      const rate = rates[token] ?? 0;
      return balance * rate >= 0.01;
    });
  }

  if (rates && order && balances) {
    order = getSortedByValue(order, balances, rates);
  }

  return (
    <InputGroup
      className={cn(`user-balance-container`, className, "user-balance-conversion")}
      size="sm"
    >
      <InputGroupButtonDropdown
        addonType="prepend"
        isOpen={isDropdownOpen}
        toggle={() => {
          setisDropdownOpen(!isDropdownOpen);
          !isDropdownOpen && setSearchValue("");
        }}
      >
        <DropdownToggle>
          <BalanceSum
            value={currencies[currency].fromDisplayValue(balance)}
            token={currency}
            tokenCaption={false}
            fiatCaption={true}
          />
          <FontAwesomeIcon
            className="button-icon-end"
            icon={isDropdownOpen ? faAngleUp : faAngleDown}
          />
        </DropdownToggle>
        <DropdownMenu style={{ minWidth: "210px" }}>
          <div className={styles.balanceButtons}>
            <button
              className={cn(styles.balanceButton, { selected: isMainTab })}
              onClick={() => setTab(BalanceTabs.MAIN)}
            >
              {t(Resources.common__main)}
            </button>
            <button
              className={cn(styles.balanceButton, { selected: !isMainTab })}
              onClick={() => setTab(BalanceTabs.CASINO)}
            >
              {t(Resources.casino)}
            </button>
          </div>

          <div className={styles.search}>
            <SearchInput
              placeholder={t("wallet__search_currency")}
              value={searchValue}
              variant={SearchInputStyles.LIGHT}
              onChange={handleSearch}
            />
          </div>

          <div className={styles.assets}>
            {order.length > 0 ? (
              order.map((currencyCode) => {
                const curr = currencies?.[currencyCode];

                return (
                  <DropdownItem
                    onClick={() => dispatch(changeCurrency(curr.name))}
                    key={curr.name}
                    className={cn({ active: currency === curr.name })}
                  >
                    <Sum
                      value={balances?.[curr.name] ?? 0}
                      token={curr.realName || curr.name}
                      tokenCaption={true}
                      balanceView={true}
                      iconSize={18}
                    />
                  </DropdownItem>
                );
              })
            ) : (
              <p className={styles.message}>{t("wallet__nothing_found")}</p>
            )}
          </div>

          {bonusTokenAvailable && (
            <>
              <div className="separator m-0" />
              <div style={{ padding: "0.25rem 0.9rem" }}>
                <img src="/img/gift.svg" alt="Bonus Icon" style={{ width: "15px" }} />
                <span style={{ fontSize: 14, color: "#A4B3CC", marginLeft: 3 }}>
                  {t("wallet__bonus_balance")}:
                </span>
              </div>
              <DropdownItem
                onClick={() => dispatch(changeCurrency(bonusTokenAvailable))}
                className={cn({ active: currency === bonusTokenAvailable })}
              >
                <Sum
                  value={balances?.[bonusTokenAvailable] ?? 0}
                  token={currencies[bonusTokenAvailable]?.realName}
                  tokenCaption={true}
                  balanceView={true}
                  iconSize={18}
                />
              </DropdownItem>
            </>
          )}
          <div className="separator m-0" />
          <div className={styles.filters}>
            <div className={styles.filter} onClick={toggleFiat}>
              <span>{t(Resources.settings__view_in)}</span>
              <label className="switch" htmlFor="toggle-fiat-checkbox">
                <input
                  type="checkbox"
                  id="toggle-fiat-checkbox"
                  checked={!!viewFiat}
                  readOnly
                />
                <span className="slider" />
              </label>
            </div>
            <div className={styles.filter} onClick={toggleHideZero}>
              <span>{t(Resources.settings__hide_zero)}</span>
              <label className="switch ml-1" htmlFor="hide-zeros-checkbox">
                <input
                  type="checkbox"
                  id="hide-zeros-checkbox"
                  checked={!!isHideZero}
                  readOnly
                />
                <span className="slider" />
              </label>
            </div>
          </div>
        </DropdownMenu>
      </InputGroupButtonDropdown>
      <InputGroupAddon addonType="append" className="btn-separated-addon">
        <NavLink
          aria-label="Open wallet"
          to={pathname + "#wallet"}
          className="btn btn-sm btn-secondary"
        >
          <WalletIcon />
        </NavLink>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default UserBalance;
