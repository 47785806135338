import Cookies from "js-cookie";
import { localStorageUtils } from "utils/browserUtils";

const keys = {
  hitid: "localStorage",
  stag: "cookie",
  subid: "localStorage",
  uid: "localStorage",
  sid: "localStorage",
} as const;

const resolvers = {
  cookie: {
    get: (key: string) => Cookies.get(key),
    set: (key: string, value: string, options?: Cookies.CookieAttributes) =>
      Cookies.set(key, value, options),
  },

  localStorage: {
    get: (key: string) => localStorageUtils?.getItem(key),
    set: (key: string, value: string) => localStorageUtils?.setItem(key, value),
  }
};

export const storage = {
  get: (key: keyof typeof keys) => {
    return resolvers[keys[key]]?.get(key);
  },
  set: (key: keyof typeof keys, value: string, options?: Cookies.CookieAttributes) =>
    resolvers[keys[key]].set(key, value, options),
};
