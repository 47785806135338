import { Tokens } from "@sbetdev2/sbet-utils/constants";
import ADA from "images/tokens/ADA.svg";
import BCH from "images/tokens/BCH.svg";
import BNB from "images/tokens/BNB.svg";
import BTC from "images/tokens/BTC.svg";
import DAI from "images/tokens/DAI.svg";
import DOGE from "images/tokens/DOGE.svg";
import EOS from "images/tokens/EOS.svg";
import ETH from "images/tokens/ETH.svg";
import LTC from "images/tokens/LTC.svg";
import SBET from "images/tokens/SBET.svg";
import SHIB from "images/tokens/SHIB.svg";
import SOL from "images/tokens/SOL.svg";
import TON from "images/tokens/TON.svg";
import TRUMP from "images/tokens/TRUMP.png";
import TRX from "images/tokens/TRX.svg";
import USDC from "images/tokens/USDC.svg";
import USDT from "images/tokens/USDT.svg";
import XRP from "images/tokens/XRP.svg";

export const tokenIcons = {
  EOS,
  SBET,
  BTC,
  LTC,
  ETH,
  USDT,
  SOL,
  USDC,
  TRX,
  DOGE,
  BCH,
  TON,
  BNB,
  ADA,
  DAI,
  XRP,
  SHIB,
  TRUMP,
  [Tokens.STRUMP]: TRUMP,
  [Tokens.STON]: TON,
  [Tokens.SBNB]: BNB,
  [Tokens.SADA]: ADA,
  [Tokens.SSHIB]: SHIB,
  [Tokens.SXRP]: XRP,
  [Tokens.SDAI]: DAI,
  [Tokens.STRX]: TRX,
  [Tokens.SBCH]: BCH,
  [Tokens.SDOGE]: DOGE,
  PBTC: BTC,
  PLTC: LTC,
  PETH: ETH,
  mBTC: BTC,
  [Tokens.SSOL]: SOL,
  [Tokens.SUSDC]: USDC,
  [Tokens.SBTC]: BTC,
  [Tokens.SLTC]: LTC,
  [Tokens.SETH]: ETH,
  [Tokens.SEOS]: EOS,
  [Tokens.SUSD]: USDT,
  [Tokens.SOL_USDT]: USDT,
  [Tokens.TRX_USDT]: USDT,
  tethertether: USDT,
  ["eosio.token"]: USDT,
  ["eth.ptokens"]: ETH,
  ["ltc.ptokens"]: LTC,
  ["btc.ptokens"]: BTC,
};
