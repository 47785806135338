import { FC } from "react";
import { useTranslation } from "react-i18next";

import { modifyExternalLinks } from "./helpers.ts";

export const HtmlContent: FC<{ html: string }> = ({ html }) => {
  const { i18n } = useTranslation();
  const modifiedHtml = modifyExternalLinks(html, i18n.language);

  return (
    <div className="html-content" dangerouslySetInnerHTML={{ __html: modifiedHtml }} />
  );
};
