import cx from "classnames";
import parse from "html-react-parser";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Resources } from "scripts/index.ts";

import { modifyExternalLinks } from "./helpers.ts";

export const TextExpander: FC<{ html: string }> = ({ html }) => {
  const [expand, setExpand] = useState(false);
  const { t, i18n } = useTranslation();
  const toggleExpand = () => setExpand((prev) => !prev);

  const modifiedHtml = modifyExternalLinks(html, i18n.language);

  return (
    <div className={cx({ expanded: expand }, "text-expand")}>
      <div className="text-expand__content">
        {!html.includes("<!-- HTML -->") ? (
          parse(modifiedHtml)
        ) : (
          <div dangerouslySetInnerHTML={{ __html: modifiedHtml }} />
        )}
        <div className="text-expand__button-wrapper">
          <button className="text-expand__button" onClick={toggleExpand}>
            {expand ? t(Resources.common__show_less) : t(Resources.common__show_more)}
          </button>
        </div>
      </div>
    </div>
  );
};
