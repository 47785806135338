import Loadable from "react-loadable";

export const ChallengePopup = Loadable({
  loader: () =>
    import(/* webpackChunkName: "challengePopup"*/ "./ChallengePopup.tsx").then(
      ({ ChallengePopup }) => ChallengePopup
    ),
  loading: () => null,
  modules: ["challengePopup"],
});
