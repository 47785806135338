import { SET_SUPPORTED_LANGUAGES } from "actions/actionTypes";

import { allowedLanguages } from "constants/i18n";

import whitelabel from "utils/whitelabelUtils.ts";

const initialState = {
  supportedLanguages: allowedLanguages,
  result: whitelabel.languages(),
};

const localeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUPPORTED_LANGUAGES:
      return action.payload;

    default:
      return state;
  }
};

export default localeReducer;
