import Loadable from "react-loadable";

export const FiatPayment = Loadable({
  loader: () =>
    import(/* webpackChunkName: "payment"*/ "./FiatPayment.tsx").then(
      ({ FiatPayment }) => FiatPayment
    ),
  loading: () => null,
  modules: ["fiat-payment"],
});
