import api, { addAuth, getAuthToken } from "api/api";

import getEosApi from "./eosApi";

//do not change it, affects api
export const transferTypes = {
  main: "main",
  casino: "casino",
};

export const fetchExchangeRates = async () => {
  const response = await api.get("/exchange-rates");

  return response.data;
};

export const fetchFiatExchangeRates = async () => {
  const response = await api.get("/exchange-rates/fiat");

  return response.data;
};

export const fetchCryptoExchangeRates = async () => {
  const response = await api.get("/exchange-rates/crypto");

  return response.data;
};

export const fetchAccountBalances = async (account) => {
  const response = await api.get(`/eos/balances-with-casino/${account}`);

  return response.data;
};
export const fetchAccountAddresses = async (account) => {
  const token = getAuthToken();
  const response = await api.get(`/wallet/getAddresses/${account}/bitgo`, addAuth(token));

  return response.data;
};

export const fetchFiatDepositConfig = async () => {
  const token = getAuthToken();
  const { data } = await api.get("/payment/config", addAuth(token));
  return data;
};

export const createFiatDepositSession = async ({ amount, currency }) => {
  const token = getAuthToken();
  if (!token) return null;

  const { data } = await api.post(
    "/payment/session",
    { order: { amount, currency } },
    addAuth(token)
  );
  return data;
};

export const casinoTransfer = async ({ code, memo, quantity, type }) => {
  const token = getAuthToken();
  if (!token) return null;
  const [amount, currency] = quantity.split(" ");
  let trx;
  if (type === transferTypes.main) {
    trx = await getEosApi().casinoDeposit(+amount, currency, memo);
  }

  return await api.post(
    `/eos-api/casino-transfer`,
    { code, memo, quantity, type, trx },
    addAuth(token)
  );
};

export const getCasinoTransferStatus = async () => {
  const token = getAuthToken();
  if (!token) return null;
  const { data } = await api.get(`/wallet/transfer-status`, addAuth(token));
  return data?.status;
};

export const walletWithdraw = async ({
  to,
  memo,
  code,
  anchor,
  transaction,
  tfaCode,
  quantity,
  providerService,
}) => {
  const token = getAuthToken();
  if (!token) return null;
  const response = await api.post(
    "/eos-api/withdraw",
    { to, anchor, transaction, code, quantity, memo, providerService },
    addAuth(token, { headers: { "tfa-code": tfaCode || 0 } })
  );
  return response.data ?? { status: "failed", message: response.error };
};
