import { Resources } from "scripts/index.ts";

export const dateOnly = (date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

const daysToTicks = (days) => 24 * 3600000 * days;

export const addDays = (date, days) => {
  return new Date(date.getTime() + daysToTicks(days));
};

export const toHHMM = (date) => {
  date = new Date(date);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return (
    (hours < 10 ? "0" + hours : "" + hours) +
    ":" +
    (minutes < 10 ? "0" + minutes : minutes)
  );
};

const options = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

export const toDate = (date, t) => {
  date = dateOnly(date);
  return t ? date.toLocaleDateString(t("locale"), options) : date.toDateString();
};

export const toNiceDate = (date, t) => {
  date = dateOnly(date);
  const dateV = date.valueOf();
  const today = dateOnly(new Date()).valueOf();
  const tt = t || ((x) => x);
  if (dateV === today) return tt(Resources.date__today);
  if (dateV === today + daysToTicks(1)) return tt(Resources.date__tomorrow);
  if (dateV === today + daysToTicks(-1)) return tt(Resources.date__yesterday);
  return t ? date.toLocaleDateString(t("locale"), options) : date.toDateString();
};

export const toNiceDateShort = (date, t) => {
  date = dateOnly(date);
  const dateV = date.valueOf();
  const today = dateOnly(new Date()).valueOf();
  const tt = t || ((x) => x);
  if (dateV === today) return tt(Resources.date__today);
  if (dateV === today + daysToTicks(1)) return tt(Resources.date__tomorrow);
  if (dateV === today + daysToTicks(-1)) return tt(Resources.date__yesterday);
  return t
    ? date.toLocaleDateString(t("locale") === "en-US" ? "en-GB" : t("locale"))
    : date.toDateString();
};

export const toNiceDateTime = (date, t) => `${toNiceDate(date, t)} ${toHHMM(date)}`;

export const toNiceTime = (date) => toHHMM(date);

export function fullSeconds(ms) {
  return Math.floor(ms / 1000) % 60;
}

export function fullMinutes(ms) {
  return Math.floor(ms / 1000 / 60) % 60;
}

export function fullHours(ms) {
  return Math.floor(ms / 1000 / 60 / 60) % 24;
}

export function fullDays(ms) {
  return Math.floor(ms / 1000 / 60 / 60 / 24) % 30;
}

const msPerHour = 60 * 60 * 1000;
const msPerDay = 24 * msPerHour;

export const toNiceCountDown = (timeLeft, t) => {
  const result = [];
  const days = Math.floor(timeLeft / msPerDay);
  if (days)
    result.push(days === 1 ? t("{{days}} day", { days }) : t("{{days}} days", { days }));
  const date = new Date(null);
  const left = timeLeft - days * msPerDay;
  date.setMilliseconds(left);
  const ss = date.getSeconds();
  result.push(
    `${Math.floor(left / msPerHour)
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${ss
      .toString()
      .padStart(2, "0")}`
  );
  return result.join(" ");
};
