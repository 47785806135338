import { FC } from "react";

import { CurrencyType } from "constants/types";

import { formatFiatAmount, formatTokenAmount } from "utils/currencyUtils";
import { tokenIcons } from "utils/iconUtils.ts";

import styles from "./AssetView.module.scss";

type Props = {
  currency: CurrencyType;
  amount: string;
  fiatAmount?: number;
  fiatCurrency?: string;
};

export const AssetView: FC<Props> = ({ currency, amount, fiatAmount, fiatCurrency }) => (
  <li className={styles.container}>
    <div className={styles.icon}>
      <img
        src={tokenIcons[currency.name] || ""}
        alt={currency.name}
        width={30}
        height={30}
      />
    </div>

    <div className={styles.name}>
      <span>{currency.walletName || currency.displayName}</span>

      {currency.mainNetwork ? (
        currency.walletName !== currency.mainNetwork ? (
          <span>{currency.mainNetwork} </span>
        ) : null
      ) : (
        <span className="d-flex align-items-end">
          <img
            src="/img/gift.svg"
            alt="Bonus Icon"
            className="mr-2"
            style={{ width: "16px" }}
          />
          BONUS BALANCE
        </span>
      )}
    </div>

    <div className={styles.amount}>
      <div className={styles.amountValue}>
        {formatTokenAmount(amount, currency.name)} {currency.displayName}
      </div>
      {fiatCurrency ? (
        <div className={styles.fiatAmount}>
          {formatFiatAmount(fiatAmount, fiatCurrency)}
        </div>
      ) : null}
    </div>
  </li>
);
