import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Spinner } from "reactstrap";
import { Resources } from "scripts/index.ts";

import { LoaderPropsType } from "./types";

export const Loader: FC<LoaderPropsType> = ({
  message = Resources.common__loading,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <Alert color="dark" className={className}>
      {t(message)} <Spinner size="sm" />
    </Alert>
  );
};
