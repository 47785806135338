import { SET_CONFIG } from "actions/actionTypes";

const initialState = {
  turnStileSiteKey: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CONFIG:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
