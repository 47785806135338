import { createSelector } from "reselect";

import { getEvent } from "selectors/eventSelectors";
import { getLeaguesById, getSport } from "selectors/sportsSelectors";

import { getBet } from "utils/eventUtils";

export const getBetBets = (state) => state.bet.bets;
export const getBetTransactionId = (state) => state.bet.transactionId;
export const getBetAmount = (state) => state.bet.betAmount;
export const getBetWinAmount = (state) => state.bet.winAmount;
export const getBetPlacing = (state) => state.bet.placing;
export const getBetError = (state) => state.bet.error;
export const getBetPlaced = (state) => state.bet.placed;
export const getBetOptions = (state) => state.bet.options;
export const getBetNoRiskBet = (state) => state.bet.noRiskBet;
export const betLimitsSelector = (state) => state.bet.betLimits;

export const getBetSlipBets = createSelector(
  getBetBets,
  getLeaguesById,
  (state) => state,
  (bets, leagues, state) =>
    bets.map((bet, i) => {
      const event = bet && getEvent(state, bet.event);
      const sport = event && getSport(state, event.sport);
      const betAmount = getBetAmount(state);
      const maxBets = state.bet.betLimits?.maxParlayBets;

      let error = null;
      if (!bet) error = "Bet not available";
      else if (!bet.bet) error = "Bet suspended";
      else if (!event || !event.bet) error = "Event is not available";
      else if (!sport) error = "Sport suspended";
      else if (betAmount.parlay && bets.find((x) => x.event === bet.event && x !== bet))
        error = "bets__parlay_multiple_restriction";
      else if (maxBets && i >= maxBets) error = "bets__parlay_amount_restriction";

      if (event && event.league) {
        const league = leagues.find((x) => x._id === event.league);
        if (league) {
          event.leagueName = league.name;
        }
      }
      const updatedBet = { ...bet };
      if (updatedBet.updatedOdd) updatedBet.odd = updatedBet.updatedOdd;
      if (updatedBet.updatedValue) updatedBet.value = updatedBet.updatedValue;
      const liveBet = getBet(event, updatedBet);

      return {
        bet: liveBet && event?.bet ? bet : { ...bet, bet: false },
        event,
        sport,
        error,
      };
    })
);
export const hasPendingBetUpdates = (state) =>
  state.bet.bets.some((bet) => {
    return (
      (bet.updatedOdd && bet.updatedOdd !== bet.odd) ||
      (bet.updatedValue && bet.updatedValue !== bet.value)
    );
  });
