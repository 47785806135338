import api, { addAuth, getAuthToken } from "api/api";

import * as scatterApi from "actions/eos/scatterapi";

const supportedProviders = {
  bitgo: "bitgo",
  ptokens: "ptokens",
};

export const eosApiAccountBalance = async (code, name) => {
  const token = getAuthToken();
  if (!token) return null;
  const response = await api.post(
    "/eos-api/account-balance",
    { code, name },
    addAuth(token)
  );
  return response.data;
};

export const eosApiBetsTransfer = async (code, bets) => {
  const token = getAuthToken();
  if (!token) return null;
  const response = await api.post(
    "/eos-api/bets-transfer",
    { code, bets },
    addAuth(token)
  );
  return response.data;
};

export const eosApiEstimatedFee = async (amount, currencyCode, units, signal) => {
  const token = getAuthToken();
  if (!token) return null;
  const currentAmount = amount ? amount : currencyCode === "BTC" ? "0.1" : "0.001";
  const response = await api.get(
    `/eos-api/fee/${currencyCode}/${units ?? currencyCode}/${currentAmount}`,
    addAuth(token),
    { signal }
  );
  return response.data;
};

export const eosApiStakeTokens = async (quantity) => {
  const token = getAuthToken();
  if (!token) return null;
  const response = await api.post("/eos-api/stake-tokens", { quantity }, addAuth(token));
  return response.data;
};

export const eosApiUnstakeTokens = async (quantity) => {
  const token = getAuthToken();
  if (!token) return null;
  const response = await api.post(
    "/eos-api/unstake-tokens",
    { quantity },
    addAuth(token)
  );
  return response.data;
};

export const eosApiClaimDividends = async () => {
  const token = getAuthToken();
  if (!token) return null;
  const response = await api.post("/eos-api/claim-dividends", {}, addAuth(token));
  return response.data;
};

const getWithdrawActionByProvider = (
  eosApi = scatterApi,
  provider = supportedProviders.bitgo
) => {
  const withdraw = eosApi.withdraw[provider];
  if (withdraw) return withdraw;
  return async () => {
    throw new Error(`Not Supported Provider: ${provider}`);
  };
};

const getAction = (name, eosApi = scatterApi) => {
  const action = eosApi[name];
  if (action) return action;
  return async () => {
    throw new Error(`Not Supported EOS Action: ${name}`);
  };
};

export const init = getAction("init");
export const login = getAction("login");
export const logout = getAction("logout");
export const betTransfer = getAction("betTransfer");
export const betsTransfer = getAction("betsTransfer");
export const betComplete = getAction("betComplete");
export const getDividends = getAction("getDividends");
export const claimDividends = getAction("claimDividends");
export const stakeTokens = getAction("stakeTokens");
export const unstakeTokens = getAction("unstakeTokens");
export const getAccountBalance = scatterApi.getAccountBalance;
export const sendWithdrawal = getAction("sendWithdrawal");
export const approveWithdrawal = getAction("approveWithdrawal");
export const rejectWithdrawal = getAction("rejectWithdrawal");
export const withdraw = getWithdrawActionByProvider();
export const casinoDeposit = getAction("casinoDeposit");
export const startCasinoGame = getAction("startCasinoGame");
