import { marketType } from "@sbetdev2/dbmodel/enums";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Badge } from "reactstrap";
import { Resources } from "scripts/index.ts";

import { getAccountIsVip } from "selectors/loginSelectors";

import { betSide, eventType } from "constants/events";
import { sports } from "constants/sports";

import BetButtonBase from "components/bet/BetButton.tsx";
import NavLink from "components/common/NavLink.tsx";

import { toHHMM } from "utils/dateUtils";
import {
  formatHdp,
  getBet,
  getEventOddCount,
  getOddsBalancedValue,
  getSportHandicapMarket,
  getSportMainMarket,
  getSportTotalMarket,
} from "utils/eventUtils";
import { getEventUrl } from "utils/urlUtils.ts";

import { ReactComponent as BlockIcon } from "img/block.svg";
import { ReactComponent as LockIcon } from "img/lock.svg";
import { ReactComponent as PlayIcon } from "img/play-icon.svg";

const BetButton = React.memo((props) => <BetButtonBase {...props} short />);

const extractMinutesFromStatus = (text) => {
  const match = (text || "").match(/(\d+)\smin/);
  return match ? `${match[1]}′` : "";
};

const ClosedLineButton = ({ isNoDrawLine, interrupted, className, disabled }) => {
  return (
    <button
      title={interrupted ? "Currently offline" : "Unavailable"}
      disabled={disabled}
      className={cx(
        "bet-button btn btn-secondary btn-sm short event-short-no-odds total-column",
        {
          "no-draw": isNoDrawLine,
        },
        className
      )}
    >
      {!interrupted ? (
        <BlockIcon width={20} height={20} />
      ) : (
        <LockIcon width={20} height={20} />
      )}
    </button>
  );
};

const EventShort = ({ event, t }) => {
  const { _id, starts, home, away, type, statusText, results } = event;
  const liveEventTime = extractMinutesFromStatus(statusText);
  const live = type === eventType.live;
  const isVip = useSelector(getAccountIsVip);

  let mlMarket = getSportMainMarket(event.sport);
  if (event.odds && !event.odds[mlMarket]) {
    if ([sports.hockey, sports.rugbyleague, sports.rugbyunion].includes(event.sport)) {
      if (event.odds[marketType.winner2MatchAll]) {
        mlMarket = marketType.winner2MatchAll;
      } else {
        mlMarket = marketType.winner3Match;
      }
    } else if ([sports.esports, sports.cricket].includes(event.sport)) {
      mlMarket = marketType.winner2Match;
    }
  }

  const winner3Match = mlMarket === marketType.winner3Match;

  const hdpMarket = getSportHandicapMarket(event.sport);
  const hdp = event.odds && event.odds[hdpMarket];
  const hdpValue = getOddsBalancedValue(hdp, (x) => x.home - x.away);

  const [hdpHome, hdpAway, winnerHome, winnerAway] = [
    { market: hdpMarket, side: betSide.home, value: hdpValue },
    { market: hdpMarket, side: betSide.away, value: hdpValue },
    { market: mlMarket, side: betSide.home },
    { market: mlMarket, side: betSide.away },
  ].map((betOptions) => getBet(event, betOptions, isVip));

  const totalMarket = getSportTotalMarket(event.sport);
  const total = event?.odds?.[totalMarket];
  const totalValue = getOddsBalancedValue(total, (x) => x.over - x.under);

  const oddCount = getEventOddCount(event);
  const isModerator = false; // todo: useSelector(getIsModer);
  const isNoDrawLine = !winner3Match && !((live || isModerator) && event.liveStream);
  const showScore = live && results && results.ft;

  const renderBetButton = ({ bet, className, text = "" }) =>
    bet ? (
      <BetButton className={className} text={text} bet={bet} />
    ) : (
      <ClosedLineButton
        isNoDrawLine={isNoDrawLine}
        interrupted={statusText === "Interrupted"}
        className={className}
        disabled={true}
      />
    );

  const totalOverBet =
    total &&
    getBet(event, { market: totalMarket, side: betSide.over, value: totalValue }, isVip);

  const totalUnderBet =
    total &&
    getBet(event, { market: totalMarket, side: betSide.under, value: totalValue }, isVip);

  return (
    <NavLink
      to={getEventUrl(event)}
      relatedIds={{
        sportId: event.sport,
        leagueId: event.league,
        eventId: event._id,
      }}
      className="event-short"
    >
      <div className="event-time text-center">
        <Badge>{live ? liveEventTime : toHHMM(starts)}</Badge>
      </div>
      <div className="event-more" style={{ position: "relative" }}>
        <Badge>
          {" "}
          {oddCount} {`>`}
        </Badge>
      </div>
      {(!!live || isModerator) && !!event.liveStream && (
        <div className={"live-stream-badge"}>
          <Badge className="live-stream-badge">
            <PlayIcon width={12} height={12} />
          </Badge>
        </div>
      )}

      <div className="event-home">
        {showScore && (
          <span className="badge badge-secondary mr-2 py-1">
            {results && results.ft && results.ft.home}
          </span>
        )}
        {t(home)}
      </div>
      <div className={winner3Match ? "event-away" : "event-away border-0"}>
        {showScore && (
          <span className="badge badge-secondary mr-2 py-1">
            {results && results.ft && results.ft.away}
          </span>
        )}
        {t(away)}
      </div>

      {winner3Match && <div className="event-draw">{t(Resources.event__draw)}</div>}

      {renderBetButton({
        bet: winnerHome,
        className: "bet-ml1",
        text: "",
      })}

      {renderBetButton({
        bet: winnerAway,
        className: "bet-ml2",
        text: "",
      })}

      {winner3Match &&
        renderBetButton({
          bet: getBet(event, { market: mlMarket, side: betSide.draw }, isVip),
          className: "bet-mlx",
        })}

      {renderBetButton({
        bet: hdpHome,
        className: "bet-hdp1",
        text: formatHdp(hdpValue),
      })}
      {renderBetButton({
        bet: hdpAway,
        className: "bet-hdp2",
        text: formatHdp(-hdpValue),
      })}

      {renderBetButton({
        bet: totalOverBet,
        className: "bet-total1",
        text: `>${totalValue}`,
      })}
      {renderBetButton({
        bet: totalUnderBet,
        className: "bet-total2",
        text: `<${totalValue}`,
      })}
    </NavLink>
  );
};

EventShort.propTypes = {
  event: PropTypes.object,
  t: PropTypes.func,
};

export default EventShort;
