import cx from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav } from "reactstrap";
import { Resources } from "scripts/index.ts";

import { getEventsCountByHours, getSportDateFilter } from "selectors/sportsSelectors";

import { setSportDateFilter } from "actions/sportActions";

const getFilterName = (filter) => {
  switch (filter) {
    case Resources.time__4H:
      return "4H";
    case Resources.time__12H:
      return "12H";
    case Resources.time__24H:
      return "24H";
    default:
      return Resources.filter__all;
  }
};

const SportDateFilter = ({ className }) => {
  const [open, setOpen] = useState(false);
  const dateFilter = useSelector(getSportDateFilter);
  const eventsCount = useSelector(getEventsCountByHours);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filterMobile = (filter) => {
    const filterName = getFilterName(filter);
    return (
      <DropdownItem
        onClick={() => dispatch(setSportDateFilter(filterName))}
        className={cx({
          active: dateFilter === filter,
        })}
      >
        <span>{t(filter || Resources.filter__all)}</span>
        <span className={"sport-date-filter__count"}>{eventsCount[filter || "ALL"]}</span>
      </DropdownItem>
    );
  };

  return (
    <div className={cx("sport-date-filter", className)}>
      <Nav pills>
        <Dropdown nav isOpen={open} toggle={() => setOpen(!open)}>
          <DropdownToggle nav caret className={cx({ active: !!dateFilter })}>
            {t(Resources.event__starts_in)}: {t(dateFilter || Resources.filter__all)}
          </DropdownToggle>
          <DropdownMenu className={"dropdown-menu-filter"}>
            {filterMobile("")}
            {filterMobile(Resources.time__4H)}
            {filterMobile(Resources.time__12H)}
            {filterMobile(Resources.time__24H)}
          </DropdownMenu>
        </Dropdown>
      </Nav>
    </div>
  );
};

SportDateFilter.propTypes = {
  className: PropTypes.string,
};

export default SportDateFilter;
