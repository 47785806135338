import cx from "classnames";
import { FC } from "react";
import { Input } from "reactstrap";

type Props = {
  id: number | string;
  label: string;
  checked?: boolean;
  className?: string;
  onChange?: () => void;
};

const Radio: FC<Props> = ({ id, checked, onChange, label, className }) => (
  <label htmlFor={`rules-tab-${id}`} className={cx("radio", className)}>
    <Input
      type="radio"
      id={`rules-tab-${id}`}
      checked={checked}
      onChange={() => {
        if (typeof onChange === "function") {
          onChange();
        }
      }}
    />
    <span className="check" />
    <span className="caption">{label}</span>
  </label>
);

export default Radio;
