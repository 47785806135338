import {
  FIAT_CONFIG_FAIL,
  FIAT_CONFIG_LOAD,
  FIAT_CONFIG_SUCCESS,
  LOGOUT_COMPLETE,
  WALLET_ADDRESSES_LOADED,
  WALLET_ADDRESSES_LOADING,
  WALLET_ADDRESSES_LOAD_ERROR,
  WALLET_BALANCES_LOADED,
  WALLET_BALANCES_LOADING,
  WALLET_BALANCES_LOAD_ERROR,
  WALLET_CASINO_BALANCES_LOADED,
  WALLET_EXCHANGE_RATES,
  WALLET_EXCHANGE_RATES_CRYPTO,
  WALLET_EXCHANGE_RATES_FIAT,
  WALLET_LOCKED_BALANCES_LOADED,
  WALLET_SET_ACTIVE_CURRENCY,
  WALLET_SET_ACTIVE_TAB,
  WITHDRAW_FEE_FAIL,
  WITHDRAW_FEE_RESET,
  WITHDRAW_FEE_START,
  WITHDRAW_FEE_SUCCESS,
} from "actions/actionTypes";

import { FiatConfigStatuses, WithdrawFeeStatuses } from "constants/wallet.ts";

import { WalletState } from "./types.ts";

const initialState: WalletState = {
  tab: null,
  currency: null,
  exchangeRates: {
    fiat: null,
    crypto: null,
    cryptoUSDT: null,
  },
  balances: {
    loading: false,
    error: null,
    data: null,
  },
  addresses: {
    loading: false,
    error: null,
    data: null,
  },
  casinoBalances: {
    loading: false,
    error: null,
    data: null,
  },
  lockedBalances: {
    loading: false,
    error: null,
    data: null,
  },
  fiatDeposit: {
    allowedCurrencies: [],
    enabled: null,
    methods: [],
    tokens: [],
    status: FiatConfigStatuses.IDLE,
  },
  withdraw: {
    fee: {
      amount: null,
      status: WithdrawFeeStatuses.IDLE,
      error: null,
    },
  },
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case WALLET_EXCHANGE_RATES:
      return {
        ...state,
        exchangeRates: action.payload.rates,
      };
    case WALLET_EXCHANGE_RATES_FIAT:
      return {
        ...state,
        exchangeRates: {
          ...state.exchangeRates,
          fiat: action.payload.rates,
        },
      };
    case WALLET_EXCHANGE_RATES_CRYPTO:
      return {
        ...state,
        exchangeRates: {
          ...state.exchangeRates,
          crypto: action.payload.rates,
        },
      };
    case WALLET_SET_ACTIVE_CURRENCY:
      return {
        ...state,
        currency: action.payload.currency,
      };
    case WALLET_SET_ACTIVE_TAB:
      return {
        ...state,
        tab: action.payload.tab,
      };
    case WALLET_BALANCES_LOADING:
      return {
        ...state,
        balances: {
          ...state.balances,
          loading: true,
          error: null,
        },
      };
    case WALLET_BALANCES_LOADED:
      return {
        ...state,
        balances: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    case WALLET_BALANCES_LOAD_ERROR:
      return {
        ...state,
        balances: {
          ...state.balances,
          loading: false,
          error: action.payload,
        },
      };
    case WALLET_CASINO_BALANCES_LOADED:
      return {
        ...state,
        casinoBalances: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    case WALLET_LOCKED_BALANCES_LOADED:
      return {
        ...state,
        lockedBalances: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    case WALLET_ADDRESSES_LOADING:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: true,
          error: null,
        },
      };
    case WALLET_ADDRESSES_LOADED:
      return {
        ...state,
        addresses: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    case FIAT_CONFIG_LOAD:
      return {
        ...state,
        fiatDeposit: {
          ...state.fiatDeposit,
          status: FiatConfigStatuses.LOADING,
        },
      };
    case FIAT_CONFIG_SUCCESS:
      return {
        ...state,
        fiatDeposit: {
          methods: action.payload.methods,
          allowedCurrencies: action.payload.allowedCurrencies,
          tokens: action.payload.tokens,
          status: FiatConfigStatuses.SUCCESS,
        },
      };
    case FIAT_CONFIG_FAIL:
      return {
        ...state,
        fiatDeposit: {
          ...state.fiatDeposit,
          status: FiatConfigStatuses.FAIL,
        },
      };
    case WALLET_ADDRESSES_LOAD_ERROR:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: false,
          error: action.payload,
        },
      };
    case WITHDRAW_FEE_START:
      return {
        ...state,
        withdraw: {
          ...state.withdraw,
          fee: {
            ...state.withdraw.fee,
            status: WithdrawFeeStatuses.LOADING,
            error: null,
          },
        },
      };
    case WITHDRAW_FEE_SUCCESS:
      return {
        ...state,
        withdraw: {
          ...state.withdraw,
          fee: {
            ...state.withdraw.fee,
            status: WithdrawFeeStatuses.SUCCESS,
            amount: action.payload,
          },
        },
      };
    case WITHDRAW_FEE_FAIL:
      return {
        ...state,
        withdraw: {
          ...state.withdraw,
          fee: {
            ...state.withdraw.fee,
            status: WithdrawFeeStatuses.FAIL,
            error: action.payload,
          },
        },
      };
    case WITHDRAW_FEE_RESET:
      return {
        ...state,
        withdraw: { ...state.withdraw, fee: initialState.withdraw.fee },
      };
    case LOGOUT_COMPLETE:
      return { ...state, addresses: initialState.addresses };
    default:
      return state;
  }
};
