import { eosApiEstimatedFee } from "api/eosApi2";
import {
  fetchAccountAddresses,
  fetchAccountBalances,
  fetchExchangeRates,
  fetchFiatDepositConfig,
  fetchFiatExchangeRates,
} from "api/walletApi";

import { getEosAccount } from "selectors/loginSelectors";
import { getWalletAddresses, getWalletBalances } from "selectors/walletSelectors";

import {
  FIAT_CONFIG_FAIL,
  FIAT_CONFIG_LOAD,
  FIAT_CONFIG_SUCCESS,
  WALLET_ADDRESSES_LOADED,
  WALLET_ADDRESSES_LOADING,
  WALLET_ADDRESSES_LOAD_ERROR,
  WALLET_BALANCES_LOADED,
  WALLET_BALANCES_LOADING,
  WALLET_BALANCES_LOAD_ERROR,
  WALLET_CASINO_BALANCES_LOADED,
  WALLET_EXCHANGE_RATES,
  WALLET_EXCHANGE_RATES_FIAT,
  WALLET_LOCKED_BALANCES_LOADED,
  WALLET_SET_ACTIVE_CURRENCY,
  WALLET_SET_ACTIVE_TAB,
  WITHDRAW_FEE_RESET,
  WITHDRAW_FEE_START,
  WITHDRAW_FEE_SUCCESS,
} from "actions/actionTypes";
import { changeCurrency } from "actions/currencyActions";
import { setShowBonus } from "actions/uiActions";

import { bonusCurrenciesOrder, currenciesOrderSocial } from "constants/currencies.ts";

import { getSavedCurrency } from "utils/browserUtils";
import { currencyAmountAvailable } from "utils/currencyUtils";

export const loadExchangeRates = () => async (dispatch) => {
  const data = await fetchExchangeRates();
  if (!data) return;
  const { fiat, crypto } = data;
  const rates = { fiat: {}, crypto: {}, cryptoUSDT: {} };
  Array.isArray(fiat) &&
    fiat.forEach((item) => {
      rates.fiat[item.currency] = item.rate;
    });
  Array.isArray(crypto) &&
    crypto.forEach((item) => {
      rates.crypto[item.currency] = item.rate;
      rates.cryptoUSDT[item.currency] = item.rateUSDT;
    });

  dispatch({ type: WALLET_EXCHANGE_RATES, payload: { rates } });
};

export const loadFiatExchangeRates = () => async (dispatch) => {
  const data = await fetchFiatExchangeRates();
  if (!data) return;
  const rates = {};
  data.forEach((item) => {
    rates[item.currency] = item.rate;
  });

  dispatch({ type: WALLET_EXCHANGE_RATES_FIAT, payload: { rates } });
};

export const walletSetActiveCurrency = (currency) => ({
  type: WALLET_SET_ACTIVE_CURRENCY,
  payload: { currency },
});

export const walletSetActiveTab = (tab) => ({
  type: WALLET_SET_ACTIVE_TAB,
  payload: { tab },
});

export const walletLoadBalances = () => async (dispatch, getState) => {
  const accountName = getEosAccount(getState());
  const pathname = window?.location?.pathname;
  const isCasinoPage = pathname.includes("casino");
  const { loading } = getWalletBalances(getState());
  if (loading || !accountName) return;
  dispatch({ type: WALLET_BALANCES_LOADING });
  try {
    const {
      balances: mainBalances,
      casinoBalance,
      lockedBalance,
    } = await fetchAccountBalances(accountName);
    const balances = isCasinoPage ? casinoBalance : mainBalances;
    loadFiatExchangeRates(balances);
    const selectedCurrency = getSavedCurrency();
    const basicCurrenciesAvailable = currencyAmountAvailable({
      balances,
      currencies: currenciesOrderSocial,
    });
    const bonusCurrenciesAvailable = currencyAmountAvailable({
      balances,
      currencies: bonusCurrenciesOrder,
    });

    //select bonus as active currency if main currencies is empty
    if (balances && !basicCurrenciesAvailable.length && bonusCurrenciesAvailable.length) {
      dispatch(changeCurrency(bonusCurrenciesAvailable[0]));
      dispatch(setShowBonus(true));
    }
    //select main balance if bonus was selected, but bonus amount is empty now
    if (
      balances &&
      bonusCurrenciesOrder.includes(selectedCurrency ?? "") &&
      !bonusCurrenciesAvailable.length
    ) {
      dispatch(changeCurrency(basicCurrenciesAvailable[0] || currenciesOrderSocial[0]));
      dispatch(setShowBonus(false));
    }
    dispatch({ type: WALLET_BALANCES_LOADED, payload: mainBalances });
    dispatch({ type: WALLET_LOCKED_BALANCES_LOADED, payload: lockedBalance });
    dispatch({ type: WALLET_CASINO_BALANCES_LOADED, payload: casinoBalance });
  } catch (e) {
    dispatch({
      type: WALLET_BALANCES_LOAD_ERROR,
      payload: "Cannot load balances",
    });
  }
};

export const walletLoadAddresses = () => async (dispatch, getState) => {
  const accountName = getEosAccount(getState());
  const { loading } = getWalletAddresses(getState());
  if (loading || !accountName) return;
  dispatch({ type: WALLET_ADDRESSES_LOADING });

  try {
    const addresses = await fetchAccountAddresses(accountName);
    dispatch({ type: WALLET_ADDRESSES_LOADED, payload: addresses });
  } catch (e) {
    dispatch({
      type: WALLET_ADDRESSES_LOAD_ERROR,
      payload: "Cannot load addresses",
    });
  }
};

export const loadFiatConfig = () => async (dispatch) => {
  try {
    dispatch({ type: FIAT_CONFIG_LOAD });

    const data = await fetchFiatDepositConfig();
    dispatch({ type: FIAT_CONFIG_SUCCESS, payload: data });
  } catch (e) {
    dispatch({ type: FIAT_CONFIG_FAIL });
  }
};

export const resetWithdrawFee = () => ({
  type: WITHDRAW_FEE_RESET,
  payload: null,
});

export const loadEstimatedFee =
  ({ amount, currencyCode, responseUnits, abortController }) =>
  async (dispatch) => {
    dispatch({ type: WITHDRAW_FEE_START });

    const checkAborted = () => {
      if (abortController.signal.aborted) {
        dispatch({ type: WITHDRAW_FEE_RESET });
        return true;
      }
      return false;
    };

    const retry = async () => {
      try {
        if (checkAborted()) return;
        const { fee, error } = await eosApiEstimatedFee(
          amount,
          currencyCode,
          responseUnits,
          abortController.signal
        );

        if (fee) {
          dispatch({ type: WITHDRAW_FEE_SUCCESS, payload: fee });
        } else {
          throw new Error(error);
        }
      } catch (e) {
        if (checkAborted()) return;
        await new Promise((resolve) => setTimeout(resolve, 2000));
        await retry();
      }
    };

    await retry();
  };
