import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";

import { sportsNavItems } from "../../constants/sports";
import { isBrowser } from "../../utils/browserUtils";
import { getLeagueUrl, getSlugWithoutId } from "../../utils/urlUtils.ts";
import NavLink from "../common/NavLink";
import EventsList from "./EventsList";

const LeagueHeader = ({ onClick, children, className }) => (
  <div onClick={onClick} className={cx("league-name", className)}>
    {children}
  </div>
);

export const LeaguesList = ({
  live,
  shortLeague,
  leagueHeader,
  invertCountBadge,
  showSportIcon,
  toggleLeague,
  open,
  isExpanded,
  x,
}) => {
  const { t } = useTranslation();
  const [isEmptyExpanded, setEmptyExpanded] = useState(false);
  const isEmpty = !x.events?.length;
  const icons = showSportIcon
    ? sportsNavItems.reduce((r, x) => {
        r[x._id] = x.iconClass;
        return r;
      }, {})
    : {};

  const icon = isEmpty
    ? isEmptyExpanded
      ? faAngleDown
      : faAngleRight
    : isExpanded
    ? faAngleDown
    : faAngleRight;

  return (
    <div key={x.league._id} className={cx("group-event-item", !open ? "unvisible" : "")}>
      {open && leagueHeader && x.league.short ? (
        <LeagueHeader
          onClick={() =>
            !isEmpty
              ? toggleLeague(getSlugWithoutId(x.league.slug))
              : setEmptyExpanded(!isEmptyExpanded)
          }
          className={cx({ "inverted-count": invertCountBadge }, !open ? "unvisible" : "")}
        >
          <span style={{ marginRight: 4, cursor: "pointer" }}>
            <FontAwesomeIcon icon={icon} />
          </span>
          <NavLink
            to={getLeagueUrl(!!live, x.league)}
            style={{
              display: "inline-flex",
              alignItems: "center",
              color: "#fff",
              position: "relative",
              paddingLeft: showSportIcon ? 30 : 0,
            }}
            onClick={(e) => {
              if (isBrowser()) {
                e.preventDefault();
              }
            }}
          >
            {showSportIcon ? (
              <div
                className={`sport-icon ${x?.league?.sport ? icons[x.league.sport] : ""}`}
                style={{
                  transform: "scale(0.7)",
                  position: "absolute",
                  left: -14,
                  top: 5,
                }}
              />
            ) : null}
            {shortLeague ? x.league.short : x.league.name}
          </NavLink>
          {!invertCountBadge ? (
            <Badge pill>{x.events ? x.events.length : 0}</Badge>
          ) : null}
        </LeagueHeader>
      ) : (
        <NavLink
          to={getLeagueUrl(!!live, x.league)}
          style={{
            display: "none",
          }}
        />
      )}
      <EventsList
        sportId={x.league.sport}
        events={x.events}
        live={live}
        isExpanded={isExpanded}
        isEmptyExpanded={isEmptyExpanded}
      />
    </div>
  );
};

export const MemoizedLeaguesList = memo(LeaguesList, (prevProps, nextProps) => {
  return !(
    !(
      nextProps.open === prevProps?.open && nextProps?.isExpanded === prevProps.isExpanded
    ) ||
    (nextProps.open && nextProps.isExpanded)
  );
});
