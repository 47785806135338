import { currencies } from "@sbetdev2/dbmodel/enums";
import {
  FormatOptions,
  getMinBet,
  getMaxBet as libsGetMaxBet,
} from "@sbetdev2/sbet-utils/eventUtils";

import { betStatus, betStatusToString } from "constants/bets";

import { isMBtc, tokenToFiat } from "utils/currencyUtils";
import { getBet, getMarketClass } from "utils/eventUtils";

import config from "../config.json";

export const getBetStatusName = (status) =>
  betStatusToString[status] || (status ? `Unknown(${status})` : "Unknown");

export const getBetCompleteReason = (status, { homeScore, awayScore, estatusText }) => {
  const statusText = getBetStatusName(status);
  switch (status) {
    case betStatus.lose:
    case betStatus.loseHalf:
    case betStatus.stakeRefund:
    case betStatus.winHalf:
    case betStatus.win:
      if (typeof homeScore === "number" && typeof awayScore === "number")
        return `${statusText}. Score ${homeScore}-${awayScore}`;
      break;
    case betStatus.cancelled:
    case betStatus.deleted:
      return estatusText ? `${statusText}: ${estatusText}` : statusText;
    default:
      break;
  }
  return statusText;
};

export const getParlayCompleteReason = (legs) => {
  if (
    Array.isArray(legs) &&
    legs.some((x) => x.result && x.result.status === betStatus.lose)
  )
    return "Lose";
  return "Settled";
};

const findSimilarValue = (event, { market, value }) => {
  if (!event || !market) return null;
  const modd = event.odds && event.odds[market];
  if (!modd) return null;
  const bet = event.odds.bet;
  if (!bet) return null;
  switch (getMarketClass(market)) {
    case "hdp":
    case "total":
      if (!modd.length) return null;
      return modd.reduce((p, x) => {
        if (Math.abs(x._id - value) < Math.abs(p - value)) return x._id;
        return p;
      }, modd[0]._id);
    default:
      return null;
  }
};

export const getSimilarBet = (event, bet) => {
  const value = findSimilarValue(event, bet);
  if (typeof value === "number") return getBet(event, { ...bet, value }) || bet;
  return bet;
};

export const getMaxWin = (token, rates, eosMaxWin) => {
  let result = 0;
  if (!token || !eosMaxWin || !rates || !rates.crypto || !rates.fiat) return result;

  if (
    (token === "EOS" && rates.crypto[token] !== 1) ||
    (token === "USD" && rates.fiat[token] !== 1)
  )
    return result;

  if (config.supportedTokens.includes(token) && rates.crypto[token])
    result = eosMaxWin / rates.crypto[token];
  else if (config.supportedFiat.includes(token) && rates.fiat[token] && rates.crypto.USDT)
    result = eosMaxWin * rates.fiat[token] * rates.crypto.USDT;
  return result;
};

const getEosMaxWin = (rates, limit) => {
  if (!rates || !rates.crypto || !rates.crypto.USDT || !limit) return 0;
  return limit * rates.crypto.USDT;
};

export const getMaxBet = (bets, events, user, currency = "EOS", rates, limit) => {
  const eosMaxWin = getEosMaxWin(rates, limit);
  const maxWin = getMaxWin(currency, rates, eosMaxWin);
  const minBet = getMinBet(currency, FormatOptions.unit);
  const maxBet = libsGetMaxBet(bets, events, user, minBet, maxWin, currency);

  return maxBet;
};

export const getParlayOdd = (bets) => {
  return bets.length === 1
    ? bets[0].odd
    : Math.trunc(bets.reduce((p, x) => p * x.odd, 100)) / 100;
};

export const getPromoMaxBet = ({
  riskFreeData,
  rates,
  noRiskHash,
  targetCurrency,
  isFiat,
  viewCurrency,
}) => {
  if (
    !riskFreeData?.currencies?.[currencies.USDT.name]?.allowed ||
    !riskFreeData?.currencies?.[currencies.USDT.name]?.max ||
    !noRiskHash
  ) {
    return null;
  }
  const maxBetUSDT = riskFreeData.currencies[currencies.USDT.name].max;

  const rate = rates?.cryptoUSDT[targetCurrency];
  if (!rate) {
    return null;
  }
  const result = maxBetUSDT / rate;

  return isFiat
    ? tokenToFiat(
        isMBtc(targetCurrency) ? result * 1000 : result,
        targetCurrency,
        viewCurrency,
        rates
      )
    : result;
};
