import { Tokens } from "@sbetdev2/sbet-utils/constants";

export enum ValidationTypes {
  WITHDRAW = "withdraw",
  TRANSFER = "transfer",
}

export type BalanceType = Record<Tokens, number>;

export type BonusTokens = Extract<
  keyof typeof Tokens,
  | "SEOS"
  | "SUSD"
  | "SSOL"
  | "SUSDC"
  | "SBTC"
  | "SLTC"
  | "SETH"
  | "SBCH"
  | "SDOGE"
  | "STON"
  | "SBNB"
  | "SADA"
  | "SXRP"
  | "STRX"
  | "SSHIB"
  | "SDAI"
  | "STRUMP"
>;

export type RatesType = Record<Tokens, number>;

export type NetworkDataType = {
  name: string;
  value: Tokens;
  token: Tokens;
  ptoken: Tokens;
};

export type ValidateWithdrawPropsType = {
  value: number;
  balances: BalanceType;
  type: ValidationTypes;
  rates?: RatesType;
  currency: Tokens;
  fee?: number | null;
};
