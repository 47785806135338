import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notificationType as notificationItemType } from "@sbetdev2/dbmodel/enums";
import React, { useEffect, useRef } from "react";
import useCountDown from "react-countdown-hook";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Resources } from "scripts/index.ts";

import { getOddsFormat } from "selectors/uiSelectors";

import { hideRecentNotification } from "actions/notificationsActions";

import {
  getBetStatus,
  getBetStatusText,
  notificationIcons,
  notificationType,
} from "constants/notifications";

import { formatOdd } from "utils/eventUtils";

const visibleTime = 10000;

const getTitle = (item, t) => {
  switch (item?.data?.type || item.type) {
    case notificationItemType.betRejected:
      if (item.count === 1) {
        return item.betIds.length
          ? t(Resources.bet_notification__bet_rejected_2, { betId: item.betIds[0] })
          : t(Resources.bet_notification__bet_rejected);
      } else {
        return t(Resources.bet_notification__bets_count_rejected, { count: item.count });
      }
    case notificationItemType.betCompleted:
      const status = getBetStatus(item?.data?.betData || {});
      const statusText = getBetStatusText(status);
      if (item?.data?.betData?._id) {
        return t(Resources[`bet_notification__bet_${statusText.toLowerCase()}_2`], {
          betId: item?.data?.betData._id,
        });
      }
      return t(Resources[`bet_notification__bet_${statusText.toLowerCase()}`]);
    case notificationItemType.activateEmail:
      return t(Resources.auth_notification__activate);
    case notificationItemType.emailChanged:
      return t(Resources.settings_notification__email_changed);
    case notificationItemType.emailActivated:
      return t(Resources.auth__activated_2);
    case notificationItemType.bonusWelcome:
      return Resources.common_notification__welcome;
    case notificationItemType.bonusActivated:
      return Resources.bonus_notification__bonus_activated;
    case notificationItemType.bonusCanceled:
      return t(Resources.bonus_notification__bonus_canceled);
    case notificationItemType.bonusCredited:
      return t(Resources.bonus_notification__no_deposit_bonus_credited);
    case notificationItemType.sessionExpired:
      return t(Resources.bonus_notification__session_expired);
    case notificationItemType.promoCodeActivated:
      return t(Resources.bonus_notification__bonus_code_activated);
    case notificationItemType.selfExcludeEmail:
      return t(Resources.self__exlude);
  }
};

const getIcon = (item) => {
  const type = notificationType(item.data ?? item);
  return notificationIcons[type];
};

const getMessage = (item, t, oddsFormat) => {
  switch (item?.data?.type || item.type) {
    case notificationItemType.betRejected:
      if (item.count === 1) {
        return item.betIds.length
          ? t(Resources.bet_notification__bet_rejected_2, { betId: item.betIds[0] })
          : t(Resources.bet_notification__bet_rejected);
      } else {
        return item.betIds.length
          ? item.betIds.length >= item.count
            ? t(Resources.bet_notification__bets_rejected, {
                bets: item.betIds.map((x) => `#${x}`).join(", "),
              })
            : t(Resources.bet_notification__bets_and_more_rejected, {
                bets: item.betIds.map((x) => `#${x}`).join(", "),
                more: item.count,
              })
          : t(Resources.bet_notification__bets_count_rejected, { count: item.count });
      }
    case notificationItemType.betCompleted:
      const betCompleted = item?.data?.betData;
      if (!betCompleted) return t(Resources.bet_notification__bet_completed);
      const betCompletedTotalOdd = Array.isArray(betCompleted.odds)
        ? betCompleted.odds.reduce((res, x) => (res * x) / 100, 1)
        : 0;
      let betCompletedSum = betCompleted.amountRaw;
      if (betCompletedTotalOdd)
        betCompletedSum += "@" + formatOdd(betCompletedTotalOdd, oddsFormat);
      const status = getBetStatus(betCompleted || {});
      const statusText = getBetStatusText(status);
      return betCompleted?.legs.length > 1
        ? t(Resources.bet_notification__bet_parlay_status_2, {
            parlay: betCompleted.legs.length,
            sum: betCompletedSum,
            status: statusText,
          })
        : t(`Bet {{sum}} {{status}}`, { sum: betCompletedSum, status: statusText });
    case notificationItemType.activateEmail:
      return t(Resources.settings_notification__confirm_email);
    case notificationItemType.emailActivated:
      return t(Resources.settings_notification__email_confirmed);
    case notificationItemType.emailChanged:
      return t(Resources.settings_notification__email_changed);
    case notificationItemType.bonusWelcome:
      return t(Resources.bonus_notification__have_bonus, {
        bonusName: (item.data && item.data.bonus && item.data.bonus.name) || "bonus",
      });
    case notificationItemType.bonusActivated:
      return t(Resources.bonus_notification__activated, {
        bonusName: (item.data && item.data.bonus && item.data.bonus.name) || "Bonus",
      });
    case notificationItemType.bonusCanceled:
      return t(Resources.bonus_notification__canceled, {
        bonusName: (item.data && item.data.bonus && item.data.bonus.name) || "Bonus",
      });
    case notificationItemType.bonusCredited:
      return t(Resources.bonus_notification__bonus_available);

    case notificationItemType.sessionExpired:
      return t("Your session has expired. Please log in again");
    case notificationItemType.selfExcludeEmail:
      return t(Resources.self__exclusion_email_instructions_sent);
  }
};

const NotificationOnsite = ({ item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const itemRef = useRef();
  const started = useRef(false);
  const oddsFormat = useSelector(getOddsFormat);
  const [timeLeft, { start }] = useCountDown(visibleTime, 100);
  const percentage = Math.floor((timeLeft * 100) / visibleTime);
  const icon = getIcon(item);

  useEffect(() => {
    if (itemRef?.current) {
      itemRef.current.classList.add("open");
    }
    setTimeout(() => {
      start();
    }, 1000);
  }, []);

  useEffect(() => {
    if (!timeLeft && !started.current) {
      started.current = true;
      return;
    }
    if (!timeLeft && itemRef?.current && started?.current) {
      itemRef.current.classList.remove("open");
      setTimeout(() => {
        dispatch(hideRecentNotification(item._id));
      }, 300);
    }
  }, [timeLeft]);

  return (
    <div
      className="notification-onsite"
      ref={itemRef}
      onClick={() => {
        dispatch(hideRecentNotification(item._id));
        !item?.withoutRedirect && history.replace(pathname + "#notifications");
      }}
    >
      <div className="notification-onsite__close">
        <FontAwesomeIcon
          icon={faTimes}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(hideRecentNotification(item._id));
          }}
        />
      </div>
      <div
        className="notification-onsite__progress"
        style={{ width: `${percentage}%` }}
      />
      <div className="notification-onsite__content">
        <div className="notification-onsite__icon">
          <FontAwesomeIcon icon={icon.icon} style={{ color: icon.color }} />
        </div>
        <div className="notification-onsite__message">
          <div className="notification-onsite__message-title">{getTitle(item, t)}</div>
          <div className="notification-onsite__message-text">
            {getMessage(item, t, oddsFormat)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationOnsite;
