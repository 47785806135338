import cx from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Input as InputBase } from "reactstrap";

import { InputBaseType } from "./types";

type Props = Omit<InputBaseType, "onChange"> & {
  icon?: string;
  caption?: string;
  className?: string;
  error?: string | null;
  onChange: (value: string) => void;
};

const Input: FC<Props> = ({
  icon,
  error,
  caption,
  onChange,
  type = "text",
  className = "",
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div className="form-group form-element">
      <div className={cx("input", className, { "input-icon": !!icon })}>
        {icon && <img src={icon} />}
        <InputBase
          type={type}
          className={cx({ invalid: !!error })}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          {...props}
        />
      </div>
      {(error || caption) && (
        <div className="input-additions">
          {error && <div className="invalid-feedback">{t(error)}</div>}
          {caption && <div className="caption">{caption}</div>}
        </div>
      )}
    </div>
  );
};

export default Input;
