import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Resources } from "scripts/index.ts";

import whitelabel, { SocialType } from "utils/whitelabelUtils.ts";

import ColumnContent from "./ColumnContent.tsx";
import NavColumn from "./NavColumn.tsx";
import { ColContent, ContentTypes, NavigationProps } from "./types.ts";

const telegramConfig =
  (whitelabel?.telegram() as Array<SocialType & { flag: string }>) ?? [];
const socialConfig =
  (whitelabel?.socials() as { title: string; url: string; type: string }[]) ?? [];

const defColumns = [
  {
    title: Resources.common__info,
    content: [
      { text: "FAQ", href: "/faq", type: ContentTypes.innerLink },
      { text: "Blog", href: whitelabel?.blogUrl(), type: ContentTypes.externalLink },
      {
        text: "Become an Affiliate",
        href: "https://sbetpartners.com",
        type: ContentTypes.externalLink,
        restrict: {
          tabIndex: -1,
        },
      },
      {
        text: "Dividends",
        href: "dividends",
        type: ContentTypes.hashLink,
        restrict: {
          browserOnly: true,
        },
      },
      { text: "Privacy Policy", href: "/privacy-policy", type: ContentTypes.innerLink },
      { text: "Rules", href: "/rules", type: ContentTypes.innerLink },
      {
        text: "Betting Integrity",
        href: "betting-integrity",
        type: ContentTypes.hashLink,
        restrict: {
          browserOnly: true,
        },
      },
      {
        text: "Responsible Gambling",
        href: "/responsible-gambling",
        type: ContentTypes.innerLink,
      },
      { text: "About Us", href: "/about-us", type: ContentTypes.innerLink },
    ],
  },
  {
    title: Resources.common__contact_us,
    content: [
      {
        text: "Support",
        href: `mailto:${whitelabel?.supportEmail()}`,
        type: ContentTypes.externalLink,
        iconPath: "/images/socials/mail.svg",
        restrict: {
          tabIndex: -1,
        },
      },
      {
        text: "Live Support",
        href: "",
        type: ContentTypes.externalLink,
        iconPath: "/images/socials/liveChat.svg",
        restrict: {
          liveChat: true,
          browserOnly: true,
          tabIndex: -1,
        },
      },
    ],
  },
  {
    title: "Community",
    content: [
      ...(telegramConfig.map((t: { url: string; flag: string }) => ({
        text: "Telegram",
        href: t.url,
        type: ContentTypes.externalLink,
        iconPath: "/images/socials/telegram.svg",
        flag: t.flag,
      })) ?? []),
      {
        text: "Wechat",
        href: "wechat",
        type: ContentTypes.hashLink,
        iconPath: "/images/socials/wechat.svg",
        flag: "cn",
      },
      ...(socialConfig.map((s: { title: string; url: string; type: string }) => ({
        text: s.title,
        href: s.url,
        type: ContentTypes.externalLink,
        iconPath: `/images/socials/${s.type}.svg`,
      })) ?? []),
    ],
  },
  {
    title: "Preferences",
    content: [{ text: "Language", href: "", type: ContentTypes.lngSelect }],
  },
];

export const Navigation: FC<NavigationProps> = ({ columns }) => {
  const { t } = useTranslation();

  return (
    <div className="row w-100 pt-3 footer-row gap-y-6">
      {(columns ?? defColumns).map((col: { title: string; content: ColContent }) => (
        <NavColumn title={t(col.title)} key={col.title}>
          {col.content.map((content) => (
            <ColumnContent
              key={content.href}
              {...content}
              title={content.text ?? ""}
              iconPath={content.icon ?? content.iconPath}
            />
          ))}
        </NavColumn>
      ))}
    </div>
  );
};
