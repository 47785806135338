import { storage } from "api/storage.ts";
import Cookies from "js-cookie";
import q from "query-string";

import { parseExperiments } from "./experiments";

export const isBrowser = () => typeof window !== "undefined";

const searchEngines = [
  "google",
  "bing",
  "yahoo",
  "yandex",
  "duckduckgo",
  "baidu",
  "naver",
  "seznam",
  "qwant",
  "ecosia",
];

export const windowUtils = {
  get: (propertyName) => {
    if (!isBrowser()) return;

    return window[propertyName];
  },
};

const localStorageGetItem = (key) => {
  try {
    if (!isBrowser()) return;
    return localStorage.getItem(key);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("localStorage.getItem: ", e);
  }
};

const localStorageSetItem = (key, item) => {
  try {
    if (!isBrowser()) return;
    return localStorage.setItem(key, item);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("localStorage.setItem: ", e);
  }
};

const localStorageRemoveItem = (key) => {
  try {
    if (!isBrowser()) return;
    return localStorage.removeItem(key);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("localStorage.removeItem: ", e);
  }
};

export const localStorageUtils = {
  getItem: localStorageGetItem,
  setItem: localStorageSetItem,
  removeItem: localStorageRemoveItem,
};

const sessionStorageGetItem = (key) => {
  try {
    if (!isBrowser()) return;
    return sessionStorage.getItem(key);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("sessionStorage.getItem: ", e);
  }
};

const sessionStorageSetItem = (key, item) => {
  try {
    if (!isBrowser()) return;
    return sessionStorage.setItem(key, item);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("sessionStorage.setItem: ", e);
  }
};

const sessionStorageRemoveItem = (key) => {
  try {
    if (!isBrowser()) return;
    return sessionStorage.removeItem(key);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("sessionStorage.removeItem: ", e);
  }
};

export const sessionStorageUtils = {
  getItem: sessionStorageGetItem,
  setItem: sessionStorageSetItem,
  removeItem: sessionStorageRemoveItem,
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getUtmParams = () => {
  try {
    const utmParams = localStorageUtils.getItem("utmParams");
    if (!utmParams) return null;
    return JSON.parse(utmParams);
  } catch (e) {
    return null;
  }
};

export const getUserSourceData = () => {
  try {
    const userSourceData = localStorageUtils.getItem("sourceData");
    if (!userSourceData) return null;
    return JSON.parse(userSourceData);
  } catch (e) {
    return null;
  }
};

export const setUserSourceData = (data) => {
  const userSourceData = getUserSourceData();
  if (userSourceData) return;
  localStorageUtils.setItem("sourceData", JSON.stringify(data));
};

export const setReferral = (referral) => {
  const expireDays = 30;
  Cookies.set("referral", referral, { expires: expireDays });
};

export const getReferral = () => {
  return Cookies.get("referral");
};

export const removeReferral = () => {
  Cookies.remove("referral");
};

export const removeStag = () => {
  Cookies.remove("stag");
};

const handleStorageParams = (urlParams) => {
  const paramsConfig = [
    { key: "hitid", urlKey: "clickid" },
    { key: "stag", urlKey: "stag", options: { expires: 30 } },
    { key: "subid", urlKey: "subid" },
    { key: "uid", urlKey: "uid" },
    { key: "sid", urlKey: "sid" },
  ];

  paramsConfig.forEach(({ key, urlKey, options }) => {
    if (!storage.get(key) && urlParams[urlKey]) {
      storage.set(key, urlParams[urlKey], options);
    }
  });

  return {
    stag: storage.get("stag"),
  };
};

export function checkReferral() {
  if (!isBrowser()) return;
  const hash = window.location.hash;
  const [_, queryParams] = hash.split("?");
  const urlParams = q.parse(window.location.search || queryParams);
  const ref = urlParams.r;
  const language = urlParams.l;

  const referrerUrl = document?.referrer;
  const userSource = determineUserSource(referrerUrl);
  const userSourceData = {
    trafficSource: userSource,
    referrer: referrerUrl,
  };
  setUserSourceData(userSourceData);

  const currentUtmParams = getUtmParams();
  const utmParams = {
    source: urlParams.utm_source || "",
    medium: urlParams.utm_medium || "",
    campaign: urlParams.utm_campaign || "",
    term: urlParams.utm_term || "",
    content: urlParams.utm_content || "",
  };
  sessionStorage.setItem("utmParams", JSON.stringify(utmParams));
  if (!currentUtmParams) {
    localStorageUtils.setItem("utmParams", JSON.stringify(utmParams));
  }
  if (language) {
    localStorageUtils.setItem("defaultLanguage", language);
  }
  const { stag } = handleStorageParams(urlParams);

  if (stag) {
    const stagRef = stag.split("_")[0];
    if (stagRef) {
      setReferral(`sbet${stagRef}`);
    }
  } else if (window.location.host === "betcrypt.io" && !ref) {
    setReferral("gobetcryptio");
  } else if (ref) {
    setReferral(ref);
  }
}

const determineUserSource = (referrer) => {
  if (!referrer) return "direct";

  if (searchEngines.some((engine) => referrer.includes(engine))) {
    return "organic_search";
  }

  return "referral";
};

export const getSavedCurrency = () => localStorageUtils.getItem("preferred_currency");

export const getIsFiatDepositEnabled = () => {
  const newExperiments = parseExperiments(document.cookie);

  return !!newExperiments?.length
    ? newExperiments?.some((e) => e?.eid === "8" && e?.vid === "2")
    : null;
};

checkReferral();

const getCPUInfo = () => {
  const hardwareConcurrency = navigator?.hardwareConcurrency || null;
  const oscpu = navigator?.oscpu || null;

  return {
    cores: hardwareConcurrency,
    architecture: navigator?.platform || null,
    oscpu,
  };
};

const getGPUInfo = () => {
  const canvas = document?.createElement("canvas");
  const gl = canvas?.getContext("webgl") || canvas?.getContext("experimental-webgl");
  if (!gl) return { renderer: null, vendor: null };

  const debugInfo = gl?.getExtension("WEBGL_debug_renderer_info");
  if (!debugInfo) return { renderer: null, vendor: null };

  return {
    renderer: gl?.getParameter(debugInfo?.UNMASKED_RENDERER_WEBGL) || null,
    vendor: gl?.getParameter(debugInfo?.UNMASKED_VENDOR_WEBGL) || null,
    webglVersion: gl?.getParameter(gl?.VERSION) || null,
    shadingLanguageVersion: gl?.getParameter(gl?.SHADING_LANGUAGE_VERSION) || null,
    extensions: gl?.getSupportedExtensions() || [],
  };
};

const getBatteryInfo = async () => {
  try {
    if ("getBattery" in navigator) {
      const battery = await navigator.getBattery();
      return {
        charging: battery.charging,
        level: battery.level * 100,
        chargingTime: battery.chargingTime,
        dischargingTime: battery.dischargingTime,
      };
    }
    return null;
  } catch (e) {
    console.error("Battery API error:", e);
    return null;
  }
};

const generateCanvasFingerprint = (deviceInfo) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = 200;
  canvas.height = 200;

  const text = `${deviceInfo.cpuInfo.cores}|${deviceInfo.gpuInfo.renderer}|${deviceInfo.screenInfo.width}x${deviceInfo.screenInfo.height}`;

  ctx.textBaseline = "top";
  ctx.font = "14px Arial";
  ctx.fillStyle = "#f60";
  ctx.fillRect(125, 1, 62, 20);
  ctx.fillStyle = "#069";
  ctx.fillText(text, 2, 15);
  ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
  ctx.fillText(text, 4, 17);

  return canvas.toDataURL();
};

const parseUserAgent = (userAgent) => {
  const parserResult = {
    deviceType: /Mobi/i.test(userAgent) ? "Mobile" : "Desktop",
    browser: {
      name: null,
      version: null,
    },
    operatingSystem: null,
    osVersion: null,
    cpuArchitecture: null,
  };

  const browserRules = [
    { regex: /Chrome\/([0-9.]+)/, name: "Chrome" },
    { regex: /Firefox\/([0-9.]+)/, name: "Firefox" },
    { regex: /Safari\/([0-9.]+)/, name: "Safari" },
    { regex: /Edge\/([0-9.]+)/, name: "Edge" },
    { regex: /OPR\/([0-9.]+)/, name: "Opera" },
  ];

  for (const rule of browserRules) {
    const match = userAgent.match(rule.regex);
    if (match) {
      parserResult.browser = {
        name: rule.name,
        version: match[1],
      };
      break;
    }
  }

  const osRules = [
    { regex: /Windows NT (\d+\.\d+)/, name: "Windows" },
    {
      regex: /Mac OS X (\d+[._]\d+)/,
      name: "Mac OS",
      transform: (v) => v?.replace("_", "."),
    },
    { regex: /Android (\d+\.\d+)/, name: "Android" },
    {
      regex: /iPhone OS (\d+[._]\d+)/,
      name: "iOS",
      transform: (v) => v?.replace("_", "."),
    },
    { regex: /Linux/, name: "Linux", version: null },
  ];

  const matchedOS = osRules.find((rule) => rule.regex.test(userAgent));
  if (matchedOS) {
    parserResult.operatingSystem = matchedOS.name;
    parserResult.osVersion = matchedOS.version || userAgent.match(matchedOS.regex)?.[1];
    if (matchedOS.transform) {
      parserResult.osVersion = matchedOS.transform(parserResult.osVersion);
    }
  }

  const cpuRules = [
    { regex: /x86_64|Win64|WOW64|amd64/i, architecture: "x86_64" },
    { regex: /i[3-6]86|x86/i, architecture: "x86" },
    { regex: /arm|aarch64/i, architecture: "ARM" },
  ];

  const matchedCPU = cpuRules.find((rule) => rule.regex.test(userAgent));
  if (matchedCPU) {
    parserResult.cpuArchitecture = matchedCPU.architecture;
  }

  return parserResult;
};

export const getUserDeviceInfo = async () => {
  let browserInfo;
  try {
    if (navigator?.userAgentData?.getHighEntropyValues) {
      const highEntropyValues = await navigator.userAgentData.getHighEntropyValues([
        "platform",
        "platformVersion",
        "architecture",
        "model",
        "uaFullVersion",
        "fullVersionList",
      ]);

      browserInfo = {
        deviceType: navigator.userAgentData.mobile ? "Mobile" : "Desktop",
        browser: {
          name: navigator.userAgentData.brands[0]?.brand || null,
          version: highEntropyValues.uaFullVersion || null,
        },
        operatingSystem: highEntropyValues.platform || null,
        osVersion: highEntropyValues.platformVersion || null,
        cpuArchitecture: highEntropyValues.architecture || null,
        deviceModel: highEntropyValues.model || null,
      };
    } else {
      browserInfo = parseUserAgent(window?.navigator?.userAgent);
    }
  } catch (e) {
    console.error("Browser info error:", e);
    browserInfo = parseUserAgent(window?.navigator?.userAgent);
  }

  const cpuInfo = getCPUInfo();
  const gpuInfo = getGPUInfo();
  const batteryInfo = await getBatteryInfo();

  const screenInfo = {
    width: window?.screen?.width || 0,
    height: window?.screen?.height || 0,
    colorDepth: window?.screen?.colorDepth || 0,
    pixelRatio: window?.devicePixelRatio || 1,
    orientation: window?.screen?.orientation?.type || null,
  };

  const deviceInfo = {
    browserInfo,
    cpuInfo: {
      ...cpuInfo,
      architecture: browserInfo.cpuArchitecture || cpuInfo.architecture,
    },
    gpuInfo,
    screenInfo,
    batteryInfo,
    memoryInfo: {
      deviceMemory: navigator?.deviceMemory || null,
      jsHeapSizeLimit: performance?.memory?.jsHeapSizeLimit,
      totalJSHeapSize: performance?.memory?.totalJSHeapSize,
      usedJSHeapSize: performance?.memory?.usedJSHeapSize,
    },
    timeZone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || null,
    language: navigator?.language || null,
    languages: navigator?.languages || [],
    vendor: navigator?.vendor || null,
    platform: navigator?.platform || null,
  };

  deviceInfo.canvasFingerprint = generateCanvasFingerprint(deviceInfo);

  return deviceInfo;
};
