import { HydrationState } from "utils/generalUtils.ts";

type Props = {
  serverCookie: string;
  isMobile: boolean;
  config: {
    turnStileSiteKey: string;
  };
};

export const useSetupHydrationState = ({ serverCookie, isMobile, config }: Props) => {
  if (
    typeof serverCookie !== "undefined" ||
    typeof isMobile !== "undefined" ||
    typeof config !== "undefined"
  ) {
    const ssrState: {
      isLoggedIn?: boolean;
      isMobile: boolean;
      config: {
        turnStileSiteKey: string;
      };
    } = {
      isMobile,
      config: { ...config },
    };
    if (serverCookie) ssrState.isLoggedIn = !!serverCookie;
    HydrationState.getInstance().setState(ssrState);
  }
};
