import Loadable from "react-loadable";

export const MirrorPopup = Loadable({
  loader: () =>
    import(/* webpackChunkName: "mirror-popup"*/ "./MirrorPopup.tsx").then(
      ({ MirrorPopup }) => MirrorPopup
    ),
  loading: () => null,
  modules: ["mirror-popup"],
});
