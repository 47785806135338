import Cookies from "js-cookie";
import { Resources } from "scripts/index.ts";

import { localStorageUtils } from "utils/browserUtils";
import { getHostname, getRootDomain } from "utils/domainUtils.ts";

export const NO_BONUS_VALUE = "NO_BONUS";
export const BONUS_RISK_FREE = "BONUS_RISK_FREE";

const domain = `.${getRootDomain(getHostname())}`;

export const getNoBonusOption = (t) => {
  return {
    value: NO_BONUS_VALUE,
    label: t(Resources.bonus_promo__without_bonus),
    params: {
      title: t(Resources.bonus_promo__without_bonus),
      text: t(Resources.bonus_promo__without_wager),
      icon: "images/bonus-icon-no-bonus-1.png",
    },
  };
};

export const getCreateBonusOptions = (bonuses = [], t) => {
  return bonuses.map((i) => {
    const label = t(i.title);
    const text = t(i.description);
    const icon = i.icon;

    return {
      value: i._id,
      label,
      params: {
        title: label,
        text: text,
        icon,
        infoUrl: i.infoUrl,
      },
    };
  });
};

export const storeSelectedBonus = ({ activeCode, bonusCode, activeBonusId }) => {
  localStorageUtils.setItem("bonusCode", bonusCode);
  if (activeCode) localStorageUtils.setItem("activeCode", activeCode);
  if (activeBonusId) localStorageUtils.setItem("activeBonusId", activeBonusId);
};

export const attachSelectedBonusWithCookies = () => {
  const bonusCode = localStorageUtils.getItem("bonusCode");
  const activeCode = localStorageUtils.getItem("activeCode");
  const activeBonusId = localStorageUtils.getItem("activeBonusId");

  if (bonusCode) Cookies.set("bonusCode", bonusCode, { domain });
  if (activeCode) Cookies.set("activeCode", activeCode, { domain });
  if (activeBonusId) Cookies.set("activeBonusId", activeBonusId, { domain });
};

export const clearStoredBonus = () => {
  localStorageUtils.removeItem("bonusCode");
  localStorageUtils.removeItem("activeBonusId");
  localStorageUtils.removeItem("activeCode");
};

export const clearAuthBonusCookies = () => {
  Cookies.remove("bonusCode", { domain });
  Cookies.remove("activeCode", { domain });
  Cookies.remove("activeBonusId", { domain });
  Cookies.remove("authDefaults", { domain });
};
