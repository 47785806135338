import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Resources } from "scripts/index.ts";

import PromoInfo from "containers/popups/PromoInfo/index.ts";

import PromoCard from "./PromoCard.tsx";

const classNames = {
  containerOuter: "main-container",
  container: "promo-grid",
  containerTitle: "promo-container__title",
};

export const promoList = [
  {
    id: "63854af0def912324c46be28",
    title: Resources.bonus_promo__deposit_play,
    coverImage: "/img/promo/cards/promoCardBanner1.webp",
    minDep: 20,
    sportRollover: 10,
    casinoRollover: 35,
    refundable: false,
    images: {
      xs: "/img/promo/cards/textPromo1_sm.svg",
      md: "/img/promo/cards/textPromo1_md-lg.svg",
    },
  },
  {
    id: "652682696e77826aa7dcb48e",
    title: Resources.bonus_promo__cash_out,
    coverImage: "/img/promo/cards/promoCardBanner2.webp",
    minDep: 20,
    sportRollover: 5,
    casinoRollover: 17,
    refundable: false,
    images: {
      xs: "/img/promo/cards/textPromo2_sm.svg",
      md: "/img/promo/cards/textPromo2_md-lg.svg",
    },
  },
  {
    id: "653a4b52fe7fdc671616b897",
    title: Resources.bonus_promo__win,
    coverImage: "/img/promo/cards/promoCardBanner3.webp",
    maxBet: 50,
    sportRollover: 5,
    casinoRollover: 17,
    refundable: true,
    images: {
      xs: "/img/promo/cards/textPromo3_sm.svg",
      md: "/img/promo/cards/textPromo3_md-lg.svg",
    },
  },
];

const PromoGrid = () => {
  const [promoInfoId, setPromoInfoId] = useState<string | null>(null);
  const { t } = useTranslation();

  const clearPromoInfoId = () => {
    setPromoInfoId(null);
  };

  return (
    <div className={classNames.containerOuter}>
      <h2 className={classNames.containerTitle}>{t(Resources.bonus__available_promo)}</h2>
      <div className={classNames.container}>
        {promoList.map((p) => (
          <PromoCard
            id={p.id}
            title={p.title}
            refundable={p.refundable}
            coverImage={p.coverImage}
            minDep={p.minDep}
            maxBet={p.maxBet}
            sportRollover={p.sportRollover}
            casinoRollover={p.casinoRollover}
            showMoreInfo={() => setPromoInfoId(p.id)}
            key={`promo-card-${p.id}`}
          />
        ))}
      </div>
      <PromoInfo promoId={promoInfoId} closeModal={clearPromoInfoId} />
    </div>
  );
};

export default PromoGrid;
