import cx from "classnames";
import usePromoVisible from "hooks/usePromoVisible.ts";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Nav, NavItem } from "reactstrap";
import { Resources } from "scripts/index.ts";

import { CASINO_CLOSED } from "constants/general";
import { baseUrls } from "constants/urls";

import NavLink from "components/common/NavLink.tsx";

type Props = { isHomepage?: boolean; navbar?: boolean };

const Menu: FC<Props> = ({ isHomepage, navbar }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const showPromo = usePromoVisible();
  const isSportsPage = pathname === "/sports";
  const isLive = /\/live$/.test(pathname);
  const isPromo = /\/promotion$/.test(pathname);
  const isCasino = /\/casino$/.test(pathname) && !pathname.includes("/history/casino");

  const links = [
    {
      name: Resources.sport__sports,
      url: `${baseUrls.prematchSports}`,
      notClickable: isSportsPage,
      enabled: true,
      active: isHomepage || isSportsPage,
      relatedIds: { sportId: 1 },
    },
    {
      name: Resources.event__live,
      url: baseUrls.liveSports,
      notClickable: isLive,
      enabled: true,
      relatedIds: { sportId: 1 },
      children: <span className="live-indicator" />,
    },
    {
      name: Resources.casino,
      url: baseUrls.casino,
      notClickable: isCasino,
      enabled: !CASINO_CLOSED,
    },
    {
      name: Resources.bonus__promotions,
      url: baseUrls.promotion,
      notClickable: isPromo,
      enabled: showPromo,
    },
  ];

  return (
    <Nav className="mr-auto" navbar={navbar}>
      {links.map(({ name, url, notClickable, enabled, children, active, relatedIds }) => {
        return (
          enabled && (
            <NavItem key={name}>
              <NavLink
                to={url}
                relatedIds={relatedIds}
                className={cx({ notClickable, active })}
              >
                {t(name)}
                {children}
              </NavLink>
            </NavItem>
          )
        );
      })}
    </Nav>
  );
};

export default Menu;
