import Loadable from "react-loadable";

export const ChangeEmailPopup = Loadable({
  loader: () =>
    import(/* webpackChunkName: "changeEmailPopup"*/ "./ChangeEmailPopup.tsx").then(
      ({ ChangeEmailPopup }) => ChangeEmailPopup
    ),
  loading: () => null,
  modules: ["changeEmailPopup"],
});
