import React from "react";
import { LoadingComponentProps } from "react-loadable";
import { Resources } from "scripts/index.ts";

import { Loader } from "./Loader";
import { LoaderPropsType } from "./types";

// in lazy Loadable components when we use this Loading as skeleton it receives extra LoadingComponentProps, but currently we don't handle them
const Loading: React.FC<LoadingComponentProps & LoaderPropsType> = ({
  message = Resources.common__loading,
  className,
}) => <Loader message={message} className={className} />;

export default Loading;
