import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Resources } from "scripts/index.ts";

import { ReactComponent as NoEventsIcon } from "img/noEvents.svg";

const NoEventsPlaceholder: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex px-3 pt-3 pb-2">
      <NoEventsIcon />
      <div className="d-flex flex-column ml-1">
        <span className="text-white small">
          {t(Resources.event__no_events_scheduled)}
        </span>
        <span className="small">{t(Resources.event__explore_other)}</span>
      </div>
    </div>
  );
};

export default NoEventsPlaceholder;
