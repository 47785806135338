import { notificationType as notificationItemType } from "@sbetdev2/dbmodel/enums";
import * as notificationsApi from "api/notificationsApi";

import { getAccountUser, getLoginToken } from "selectors/loginSelectors";
import {
  getAllNotificationsLoading,
  getNotificationsRecentLastId,
  getNotificationsRecentLoading,
  getUnreadNotificationsLoading,
} from "selectors/notificationsSelectors";

import {
  NOTIFICATIONS_ALL_ERROR,
  NOTIFICATIONS_ALL_LOADED,
  NOTIFICATIONS_ALL_LOADING,
  NOTIFICATIONS_READ,
  NOTIFICATIONS_READ_ALL_LOADED,
  NOTIFICATIONS_READ_ALL_LOADING,
  NOTIFICATIONS_RECENT_CLEAR,
  NOTIFICATIONS_RECENT_ERROR,
  NOTIFICATIONS_RECENT_HIDE,
  NOTIFICATIONS_RECENT_LOADED,
  NOTIFICATIONS_RECENT_LOADING,
  NOTIFICATIONS_UNREAD_ERROR,
  NOTIFICATIONS_UNREAD_LOADED,
  NOTIFICATIONS_UNREAD_LOADING,
} from "actions/actionTypes";

const groupRecentNotifications = (items) => {
  const result = [];
  const byTypes = {
    betRejected: [],
  };

  for (const item of items) {
    switch (item.type) {
      case notificationItemType.betRejected:
        byTypes.betRejected.push(item);
        break;
      case notificationItemType.betCompleted:
        result.push({
          _id: item._id,
          type: notificationItemType.betRejected,
          betIds: [item?.betData?._id],
          count: 1,
          data: item,
        });
        break;
      case notificationItemType.activateEmail:
      case notificationItemType.emailActivated:
      case notificationItemType.emailChanged:
      case notificationItemType.bonusWelcome:
      case notificationItemType.bonusActivated:
      case notificationItemType.bonusCanceled:
      case notificationItemType.bonusCredited:
      case notificationItemType.selfExcludeEmail:
        result.push({
          _id: item._id,
          type: item.type,
          count: 1,
          data: item,
        });
        break;
    }
  }

  if (byTypes.betRejected && byTypes.betRejected.length) {
    result.push({
      _id: byTypes.betRejected[0]._id,
      type: notificationItemType.betRejected,
      betIds: byTypes.betRejected.map((x) => x?.betData?.betId).filter((x) => !!x),
      count: byTypes.betRejected.length,
    });
  }

  return result;
};

export const loadRecentNotifications = () => async (dispatch, getState) => {
  try {
    const user = getAccountUser(getState());
    const state = getState();
    const token = getLoginToken(state);
    if (!token) return;
    const loading = getNotificationsRecentLoading(state);
    if (loading) return;
    dispatch({ type: NOTIFICATIONS_RECENT_LOADING });
    const lastId = getNotificationsRecentLastId(state);
    const data = await notificationsApi.fetchRecentNotifications(token, lastId);
    if (!data || !user) throw new Error("Cannot load recent notifications");
    const newLastId =
      Array.isArray(data?.items) && data.items.length ? +data.items[0]._id : 0;
    dispatch({
      type: NOTIFICATIONS_RECENT_LOADED,
      payload: {
        unreadNotifications: data?.unreadNotifications || 0,
        pendingBets: data?.pendingBets || 0,
        items: user.onsiteEnabled ? groupRecentNotifications(data?.items || []) : [],
        lastId: user.onsiteEnabled ? +newLastId : 0,
      },
    });

    if (!user.onsiteEnabled) {
      dispatch({ type: NOTIFICATIONS_RECENT_CLEAR });
    }

    setTimeout(() => {
      dispatch(loadRecentNotifications());
    }, 10000);
  } catch (e) {
    console.log(e);
    dispatch({ type: NOTIFICATIONS_RECENT_ERROR });
  }
};

export const hideRecentNotification = (id) => ({
  type: NOTIFICATIONS_RECENT_HIDE,
  payload: { id },
});

export const loadUnreadNotifications = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = getLoginToken(state);
    if (!token) return;
    const loading = getUnreadNotificationsLoading(state);
    if (loading) return;
    dispatch({ type: NOTIFICATIONS_UNREAD_LOADING });
    const items = await notificationsApi.fetchUnreadNotifications(token);
    dispatch({ type: NOTIFICATIONS_UNREAD_LOADED, payload: { items } });
  } catch (e) {
    dispatch({
      type: NOTIFICATIONS_UNREAD_ERROR,
      payload: { error: "Cannot load notifications" },
    });
  }
};

export const readNotification = (id) => async (dispatch, getState) => {
  try {
    const token = getLoginToken(getState());
    if (!token) return;
    await notificationsApi.fetchReadNotification(token, id);
    dispatch({ type: NOTIFICATIONS_READ, payload: { id } });
  } catch (e) {}
};

export const readAllNotifications = () => async (dispatch, getState) => {
  try {
    const token = getLoginToken(getState());
    if (!token) return;
    dispatch({ type: NOTIFICATIONS_READ_ALL_LOADING });
    await notificationsApi.fetchReadAllNotifications(token);
    dispatch({ type: NOTIFICATIONS_READ_ALL_LOADED });
  } catch (e) {}
};

export const readNotificationsByIds = (ids) => async (dispatch, getState) => {
  try {
    const token = getLoginToken(getState());
    if (!token) return;
    await notificationsApi.sendReadNotificationsByIds(token, ids);
  } catch (e) {}
};

export const loadAllNotifications = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = getLoginToken(state);
    if (!token) return;
    const loading = getAllNotificationsLoading(state);
    if (loading) return;
    dispatch({ type: NOTIFICATIONS_ALL_LOADING });
    const items = await notificationsApi.fetchAllNotifications(token);
    dispatch({ type: NOTIFICATIONS_ALL_LOADED, payload: { items } });
  } catch (e) {
    dispatch({
      type: NOTIFICATIONS_ALL_ERROR,
      payload: { error: "Cannot load notifications" },
    });
  }
};
