import { Tokens } from "@sbetdev2/sbet-utils/constants/tokens";

import { BalanceType, RatesType } from "../../types";

type GetSortedByValueType = (
  order: Tokens[],
  balance: BalanceType,
  rates: RatesType
) => Tokens[];

export const getSortedByValue: GetSortedByValueType = (order, balance, rates) => {
  const getTotalValue = (token: Tokens) => (balance[token] || 0) * (rates?.[token] || 0);

  const filteredOrder = order.filter((token) => balance[token] > 0);

  return Array.from(
    new Set([
      ...filteredOrder.sort((a, b) => getTotalValue(b) - getTotalValue(a)),
      ...order,
    ])
  );
};
