import { altCoins } from "./currencies";

export const casinoPaginationChangeType = {
  add: "add",
  set: "set",
};

export const getCategoriesWithCoins = (categories = []) => {
  return altCoins.reduce((a, v) => {
    const newCategories = categories.map((c) => `${v}-${c}`);

    return [...a, ...newCategories];
  }, []);
};
