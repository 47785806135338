import { Resources } from "scripts/index.ts";

export enum WalletTabs {
  DEPOSIT = "Deposit",
  WITHDRAW = "Withdraw",
  HISTORY = "History",
  CASINO_HISTORY = "Transfer history",
  TRANSFER = "Transfer",
  OVERVIEW = "Wallet",
}

export enum BalanceTabs {
  MAIN = "1",
  CASINO = "2",
}

export const classNames = {
  greenButton: "rounded-lg btn-success submit-action btn btn-primary",
  primaryButton: "btn btn-type-1 btn-primary",

  transferContainer: "wallet-transfer__buttons",
  transferButton: "wallet-transfer__buttons__button",
};

export const getWalletSections = () => [
  {
    id: BalanceTabs.MAIN,
    label: Resources.common__main,
    buttons: [
      {
        label: Resources.deposit,
        tab: WalletTabs.DEPOSIT,
        style: classNames.greenButton,
      },
      {
        label: Resources.withdraw,
        tab: WalletTabs.WITHDRAW,
        style: classNames.primaryButton,
      },
      {
        label: Resources.history,
        tab: WalletTabs.HISTORY,
        style: classNames.primaryButton,
      },
    ],
  },
  {
    id: BalanceTabs.CASINO,
    label: Resources.casino,
    buttons: [
      {
        label: Resources.wallet__transfer,
        tab: WalletTabs.TRANSFER,
        style: classNames.greenButton,
      },
      {
        label: Resources.history,
        tab: WalletTabs.CASINO_HISTORY,
        style: classNames.primaryButton,
      },
    ],
  },
];
