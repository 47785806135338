import initI18n from "config/i18n";
import LanguageDetector from "i18next-browser-languagedetector";
import Cookies from "js-cookie";
import qs from "query-string";
import { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Loadable from "react-loadable";
import { Provider as ReduxProvider, useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch, useParams } from "react-router-dom";
import HashRoutes from "routes/HashRoutes";
import { Resources } from "scripts/index.ts";
import "typeface-roboto";

import BetSlipHandler from "containers/BetSlipHandler/BetSlipHandler";
import LicenseAgreement from "containers/LicenseAgreement";
import LoginHandler from "containers/LoginHandler/LoginHandler";
import NotificationsOnsite from "containers/NotificationsOnsite/NotificationsOnsite";
import { ChallengePopup } from "containers/popups/ChallengePopup";
import { ChangeEmailPopup } from "containers/popups/ChangeEmailPopup";
import { MirrorPopup } from "containers/popups/MirrorPopup";
import TfaConfirm from "containers/popups/TfaConfirm/TfaConfirm";
import Unsubscribe from "containers/popups/Unsubscribe/Unsubscribe";
import VpnSuggestions from "containers/popups/VpnSuggestionsPopup";

import { getIsMirrorPopUpAvailable } from "selectors/countrySelectors";

import BrowserOnly from "components/common/BrowserOnly.tsx";
import ErrorPopup from "components/common/ErrorPopup";

import { localStorageUtils, sessionStorageUtils } from "utils/browserUtils";
import { getHostname, getRootDomain } from "utils/domainUtils.ts";
import { xorDecrypt } from "utils/encryptUtils";
import startMonitoring from "utils/monitoring";

import App from "./App";
import configureStore from "./config/store";
import { getIsPopupOpen } from "./selectors/casinoSelectors";
import { getChallenge } from "./selectors/challengeSelectors";
import { getChangeEmailPopupShown } from "./selectors/loginSelectors";
import "./styles/index.scss";

const initialState = JSON.parse(xorDecrypt(window.__REDUX_STATE__)) || {};
const { ui, settings, supportedLanguages } = initialState || {};

if (ui) {
  initialState.ui.viewFiat = !!+localStorage.getItem("fiat_view");
}
if (settings) {
  initialState.settings.fiatCurrency = localStorageUtils.getItem("fiat_currency") || null;
}
export const store = configureStore(initialState);
startMonitoring(store);

initI18n(LanguageDetector, supportedLanguages?.supportedLanguages);

const AuthHandler = () => {
  const [authError, setAuthError] = useState(null);
  const error = null;

  try {
    const token = Cookies.get("token");
    if (token) {
      const domain = `.${getRootDomain(getHostname())}`;
      Cookies.remove("token", { path: "/", domain });
      localStorageUtils.setItem(
        "auth",
        JSON.stringify({ token: decodeURIComponent(token), social: true })
      );
      window.history.replaceState(
        {},
        document.title,
        window.location.href.replace(/\?.+$/, "")
      );
    }
  } catch (e) {
    console.log(e);
  }

  useEffect(() => {
    setAuthError(error);
  }, [error]);

  if (authError) {
    return (
      <ErrorPopup
        title={Resources.auth__sign_in}
        onClose={() => {
          window.history.replaceState(
            {},
            document.title,
            window.location.href.replace(/\?.+$/, "")
          );
          setAuthError(null);
        }}
        errorMessage={authError}
      />
    );
  }

  return null;
};

const ActivateAccount = () => {
  const { type, code } = useParams();
  const { email, contact } = qs.parse(window.location.search);
  sessionStorageUtils.setItem("activationType", type);
  sessionStorageUtils.setItem("activationCode", code);
  sessionStorageUtils.setItem("activationEmail", email);
  if (contact === "1") {
    sessionStorageUtils.setItem("activateContactEmail", 1);
  }
  if (!type || !code || !email) return <Redirect to="/sports/soccer" />;
  return <Redirect to="/sports/soccer#activate" />;
};

const Popups = () => {
  const { open: isChallengePopupShown } = useSelector(getChallenge);
  const isChangeEmailPopupShown = useSelector(getChangeEmailPopupShown);
  const isMirrorPopupAvailable = useSelector(getIsMirrorPopUpAvailable);
  const isMirrorPopupShown = localStorageUtils.getItem("mirrorPopupShown") === "2";
  const isCasinoPopupShown = useSelector(getIsPopupOpen);

  return (
    <>
      {isChallengePopupShown && <ChallengePopup />}
      {isCasinoPopupShown && <VpnSuggestions />}
      {isChangeEmailPopupShown && <ChangeEmailPopup />}
      {isMirrorPopupAvailable && !isMirrorPopupShown && <MirrorPopup />}
    </>
  );
};

const AppBundle = (
  <ReduxProvider store={store}>
    <BrowserRouter>
      <Suspense fallback={<div className="page-loading">Loading...</div>}>
        <Switch>
          <Route path="/activate/:type?/:code?" component={ActivateAccount} />
        </Switch>
        <BrowserOnly>
          <LoginHandler />
          <HashRoutes />
          <BetSlipHandler />
          <AuthHandler />
          <LicenseAgreement />
          <Unsubscribe />
          <TfaConfirm />
          <Popups />
          <NotificationsOnsite />
        </BrowserOnly>
        <App />
      </Suspense>
    </BrowserRouter>
  </ReduxProvider>
);

window.onload = () => {
  Loadable.preloadReady().then(() => {
    ReactDOM.render(AppBundle, document.getElementById("root"));
  });
};
