import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button } from "reactstrap";
import { Resources } from "scripts/index.ts";

import { PromoData } from "containers/popups/PromoInfo/PromoInfo.tsx";

import { getLoginProgress } from "selectors/loginSelectors";

import { baseUrls } from "constants/urls";

import { localStorageUtils } from "utils/browserUtils";

type Props = PromoData & { showMoreInfo: () => void };

const PromoCard: React.FC<Props> = ({
  id,
  title,
  minDep,
  maxBet,
  coverImage,
  sportRollover,
  casinoRollover,
  showMoreInfo,
}) => {
  const logininProgress = useSelector(getLoginProgress);

  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={"promo-grid__card d-flex flex-column"}>
      <div className={"promo-grid__card-zoom"}>
        <picture>
          <source srcSet={coverImage} type="image/png" />
          <img src={coverImage} alt={title} />
        </picture>
      </div>
      <div
        className="promo-grid__card-body d-flex flex-column justify-content-between"
        style={{ flex: 2 }}
      >
        <div>
          <h6 className="mb-3 promo-grid__card-title">{t(title)}</h6>
          <ul className="promo-grid__card-benefits">
            {!!minDep && (
              <li>
                {t(Resources.bonus_promo__min_deposit)}:{" "}
                <b>
                  ${minDep} {t(Resources.bonus_promo__in_crypto)}
                </b>
              </li>
            )}
            {!!maxBet && (
              <li>
                {t(Resources.bonus_promo__max_bet)}:{" "}
                <b>
                  ${maxBet} {t(Resources.bonus_promo__in_crypto)}
                </b>
              </li>
            )}
            <li>
              {t(Resources.bonus_promo__rollover_sport)}: <b>{sportRollover}x</b>
            </li>
            <li>
              {t(Resources.bonus_promo__rollover_casino)}: <b>{casinoRollover}x</b>
            </li>
          </ul>
        </div>
        <div>
          <Button
            className="rounded btn-success btn-black-text w-100"
            onClick={() => {
              localStorageUtils.setItem("activeBonusId", id);
              history.push(`#signup`, { from: baseUrls.promotion });
            }}
            disabled={!!logininProgress}
          >
            {t(Resources.auth__join_now)}
          </Button>
          <a
            className="mt-2 w-100 d-block text-center p-2"
            onClick={(e) => {
              e.preventDefault();
              showMoreInfo();
            }}
          >
            {t(Resources.common__more_info)}
          </a>
        </div>
      </div>
    </div>
  );
};

export default PromoCard;
