import { createContext } from "react";

import { WalletTabs } from "./constants.ts";

type WalletContextType = {
  setActiveTab?: (tab: WalletTabs) => void;
  setBackButtonAction?: (action: () => void) => { backButton: () => void };
};

export const WalletContext = createContext<WalletContextType>({});
