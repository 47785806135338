import { getGameListFavouriteCount, getGameSessionId } from "selectors/casinoSelectors";
import { getEosAccount } from "selectors/loginSelectors";

import {
  CASINO_GAME_DEMO_END,
  CASINO_GAME_DEMO_ERROR,
  CASINO_GAME_DEMO_STARTED,
  CASINO_GAME_DEMO_STARTING,
  CASINO_GAME_DEPOSIT_SHOWN_SET,
  CASINO_GAME_END,
  CASINO_GAME_ERROR,
  CASINO_GAME_LOADED,
  CASINO_GAME_LOADING,
  CASINO_GAME_POPUP_CLOSE,
  CASINO_GAME_POPUP_OPEN,
  CASINO_GAME_RELATED_LOADED,
  CASINO_GAME_SET_ID,
  CASINO_GAME_STARTED,
  CASINO_GAME_STARTING,
} from "actions/casino/actionTypes";
import {
  addFavourite,
  casinoGameStart,
  deleteFavourite,
  endGame,
  fetchGame,
  fetchGameBySlug,
  fetchRelatedGames,
  startGameDemo,
} from "actions/casino/api";
import { showLoginPopup, updateBalance } from "actions/loginActions.ts";
import { walletLoadBalances } from "actions/walletActions";

import { isMBtc } from "utils/currencyUtils";

import { setFavouriteCount } from "./gameListActions";

export const loadRelatedGames =
  (gameId, provider, limit = 4) =>
  async (dispatch, getState) => {
    const eosAccount = getEosAccount(getState());
    try {
      const data = await fetchRelatedGames(provider, eosAccount, limit);
      const relatedGames = Array.isArray(data)
        ? data.filter((x) => x._id !== gameId).slice(0, limit)
        : null;
      dispatch({
        type: CASINO_GAME_RELATED_LOADED,
        payload: relatedGames,
      });
    } catch (e) {
      // do nothing
    }
  };

export const setGameError = (error = "Game is not available") => ({
  type: CASINO_GAME_ERROR,
  payload: error,
});

export const loadGame = (gameId) => async (dispatch, getState) => {
  dispatch({ type: CASINO_GAME_LOADING });
  const eosAccount = getEosAccount(getState());
  try {
    const data = await fetchGame(gameId, eosAccount);
    if (!data.enabled) {
      dispatch(setGameError());
    } else {
      dispatch({ type: CASINO_GAME_LOADED, payload: data });
      dispatch(loadRelatedGames(gameId, data.provider));
    }
  } catch (e) {
    dispatch(setGameError());
  }
};

export const loadGameBySlug = (slug) => async (dispatch) => {
  dispatch({ type: CASINO_GAME_LOADING });

  try {
    const data = await fetchGameBySlug(slug);

    if (!data.enabled) {
      dispatch(setGameError());
    } else {
      dispatch({ type: CASINO_GAME_LOADED, payload: data });
      dispatch(loadRelatedGames(slug, data.provider));
      dispatch({
        type: CASINO_GAME_SET_ID,
        payload: data._id,
      });
    }
  } catch (e) {
    dispatch(setGameError());
  }
};

export const toggleFavourite = (gameId, isFavourite) => async (dispatch, getState) => {
  const state = getState();

  const eosAccount = getEosAccount(state);
  if (!eosAccount) {
    dispatch(showLoginPopup());
    return;
  }

  const favouriteCount = getGameListFavouriteCount(state);

  if (isFavourite) {
    await deleteFavourite(gameId, eosAccount);
    favouriteCount > 0 && dispatch(setFavouriteCount(favouriteCount - 1));
  } else {
    await addFavourite(gameId, eosAccount);
    dispatch(setFavouriteCount(favouriteCount + 1));
  }
};

export const startDemoPlay = (gameId, language) => async (dispatch) => {
  try {
    dispatch({ type: CASINO_GAME_DEMO_STARTING });
    const { url } = await startGameDemo({ gameId, language });
    dispatch({ type: CASINO_GAME_DEMO_STARTED, payload: url });
  } catch (e) {
    dispatch({ type: CASINO_GAME_DEMO_ERROR, payload: "Game cannot be started" });
  }
};

export const switchGameType = () => ({
  type: CASINO_GAME_DEPOSIT_SHOWN_SET,
  payload: true,
});

export const hideDeposit = () => ({
  type: CASINO_GAME_DEPOSIT_SHOWN_SET,
  payload: false,
});

export const endGameDemo = () => ({
  type: CASINO_GAME_DEMO_END,
});

export const playGame =
  ({ gameId, language, currency, token, rate }) =>
  async (dispatch) => {
    try {
      dispatch({ type: CASINO_GAME_STARTING });
      const { _id, url } = await casinoGameStart({
        token,
        currency: isMBtc(currency) ? "mBTC" : currency === "EOS" ? "mEOS" : currency,
        game_uuid: gameId,
        language,
      });

      dispatch({
        type: CASINO_GAME_STARTED,
        payload: {
          sessionId: _id,
          gameUrl: url,
          currency,
          rate,
          userCurrency: token,
        },
      });
      dispatch(updateBalance());
      dispatch(walletLoadBalances());
    } catch (e) {
      dispatch({ type: CASINO_GAME_DEMO_ERROR, payload: "Game cannot be started" });
    }
  };

export const endCasinoGame = () => async (dispatch, getState) => {
  try {
    const sessionId = getGameSessionId(getState());
    if (sessionId) {
      await endGame(sessionId);
    }
  } catch (e) {
    // do nothing
  } finally {
    dispatch({ type: CASINO_GAME_END });
    dispatch(updateBalance());
  }
};

export const startFunPlayInsteadOfReal = (gameId, language) => async (dispatch) => {
  await dispatch(endCasinoGame());
  await dispatch(startDemoPlay(gameId, language));
};
export const openGamePopup = (gameData) => ({
  type: CASINO_GAME_POPUP_OPEN,
  payload: gameData,
});

export const closeGamePopup = () => ({
  type: CASINO_GAME_POPUP_CLOSE,
});
