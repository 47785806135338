import api, { addAuth } from "api/api";
import Cookies from "js-cookie";

import { getUserSourceData } from "utils/browserUtils";

import config from "../config.json";
import { apiType, getApi } from "./apiFactory";

export const initUserSession = async (provider, authData, { timeout } = {}) => {
  const { data } = await getApi(apiType.serverApi).post(
    "/sessions/init",
    { provider, authData },
    {
      params: { timeout },
      headers: { "Content-Type": "application/json" },
      timeout: config.apiTimeout,
    }
  );

  return data;
};

export const fetchUserInfo = async (token) => {
  const { data } = await api.get(`/sessions/user`, addAuth(token));

  return data;
};

export const validateSession = async (token) => {
  const { data } = await api.get("/sessions/validate-session", addAuth(token));

  return data;
};

export const sendUserMetaData = async (userMetaData) => {
  const { data } = await api.post("/auth/meta-data", {
    ...userMetaData,
  });
  return data;
};

export const invalidateUserSession = async (token) => {
  await api.get("/sessions/invalidate", addAuth(token));
};

export const acceptLicense = async (token) => {
  const resp = await getApi(apiType.serverApi).get(
    "/users/accept-license-agreement",
    addAuth(token)
  );
  return resp.data;
};

export const resendActivation = async (token) => {
  const resp = await api.post("/login/resend-activation", {}, addAuth(token));
  return resp.data;
};

export const updateUserMetaInfo = async (token, referral, utm) => {
  await api.post(
    "/users/meta-info",
    {
      referral,
      utm,
    },
    addAuth(token)
  );
};

export const assignEOSAccount = async (userId, { timeout } = {}) => {
  const response = await api.get(`/eos/get-account/${userId}`, {
    params: { timeout },
  });
  const { eosAccount } = response.data;

  return eosAccount;
};

export const userLogin = async (data) => {
  const response = await getApi(apiType.serverApi).post("/login", data, {
    timeout: config.apiTimeout,
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
};

export const userCreate = async (data) => {
  let referral = "";
  let utms = "";
  let bonusCode = "";
  const userSourceData = getUserSourceData();
  try {
    referral = Cookies.get("referral") || "";
    utms = localStorage.getItem("utmParams") || "";
    bonusCode = localStorage.getItem("bonusCode") || "";
  } catch (e) {}
  const response = await getApi(apiType.serverApi).post(
    "/login/create?later=1",
    {
      ...data,
      referral,
      utms,
      bonusCode,
      userSourceData,
    },
    {
      timeout: config.apiTimeout,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const userResetPassword = async (data) => {
  const response = await api.post("/login/reset-password", data);
  return response.data;
};

export const userActivateAccount = async (data) => {
  const response = await api.post("/login/activate-account", data);
  return response.data;
};

export const userConfirmReset = async (data) => {
  const response = await api.post("/login/activate-reset", data);
  return response.data;
};

export const userSetNewPassword = async (data) => {
  const response = await api.post("/login/new-password", data);
  return response.data;
};

export const saveChangePassword = async (token, currentPassword, newPassword) => {
  return await api.post(
    "/login/change-password",
    { currentPassword, newPassword },
    addAuth(token)
  );
};
