import cx from "classnames";
import ClientSideRenderer from "hoc/ClientSideRenderer.tsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Container, Navbar, NavbarToggler } from "reactstrap";

import SignInButton from "containers/User/SignInButton";
import SignUpButton from "containers/User/SignUpButton";
import UserBalance from "containers/User/UserBalance";
import UserMenu from "containers/User/UserMenu";

import { getAccountName, getEosAccount } from "selectors/loginSelectors";
import { getNotificationsCounter } from "selectors/notificationsSelectors";
import { isSideMenuOpened } from "selectors/uiSelectors";

import { toggleSideMenu } from "actions/uiActions";

import { baseUrls } from "constants/urls";

import BrowserOnly from "components/common/BrowserOnly.tsx";
import HashLink from "components/common/HashLink";
import NavLink from "components/common/NavLink.tsx";
import BonusStrip from "components/info-strip/BonusStrip";

import { ReactComponent as HistoryIcon } from "img/header-icons/history-icon.svg";
import { ReactComponent as NotificationIcon } from "img/header-icons/notification-icon.svg";

import Menu from "./Menu";

function Header() {
  const menuOpened = useSelector(isSideMenuOpened);
  const account = useSelector(getAccountName);
  const eosAccount = useSelector(getEosAccount);
  const isLoggedIn = !!account;
  const dispatch = useDispatch();
  const counter = useSelector(getNotificationsCounter);
  const [isShadow, setShadow] = useState(false);
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const isHomepage =
    pathname === "/" || pathname.replace(/[/]/g, "") === `${i18n.language}`;

  useEffect(() => {
    if (typeof window === "undefined") return;

    const handleScroll = () => setShadow(window.pageYOffset > 5);

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      className={cx(`header-container`, {
        "header-container_shadow": isShadow,
      })}
    >
      <BonusStrip />
      <Navbar color="dark" dark expand="lg">
        <Container className="justify-content-start">
          <ClientSideRenderer>
            {!menuOpened && (
              <NavbarToggler
                onClick={() => dispatch(toggleSideMenu())}
                className="p-0 border-0 mr-2"
              />
            )}
          </ClientSideRenderer>

          <NavLink
            to={baseUrls.home}
            className={cx(
              "navbar-brand site-logo d-none d-block header-logo flex-grow-1 flex-sm-grow-0 mr-2",
              { notClickable: isHomepage }
            )}
          />

          <div className="main-menu d-none d-lg-block">
            <Menu isHomepage={isHomepage} navbar />
          </div>

          <nav className="login-status-container ml-auto mt-1">
            <span className="d-flex user-actions">
              <BrowserOnly>
                {isLoggedIn ? (
                  <>
                    <UserBalance />
                    <div className="d-none user-menu-container">
                      <UserMenu className="ml-3 mr-2" />
                    </div>
                    <div className="widget-counters">
                      <HashLink
                        to={`notifications`}
                        className="d-md-flex position-relative ml-1 leaderboard-nav-link widget-link"
                      >
                        <NotificationIcon />
                        {!!counter.notifications && (
                          <span className="widget-counter">{counter.notifications}</span>
                        )}
                      </HashLink>
                      {eosAccount && (
                        <NavLink
                          to={`/history`}
                          className="d-md-flex position-relative ml-1 history-nav-link widget-link"
                        >
                          <HistoryIcon />
                          {!!counter.pendingBets && (
                            <span className="widget-counter">{counter.pendingBets}</span>
                          )}
                        </NavLink>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <SignInButton className="d-block" />
                    <SignUpButton className="d-block" />
                  </>
                )}
              </BrowserOnly>
            </span>
          </nav>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
