import { isExternalLink } from "utils/urlUtils.ts";

export const modifyExternalLinks = (html: string, currentLanguage: string) => {
  if (!html) return "";

  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      const href = link.getAttribute("href");

      if (!href) return;
      if (isExternalLink(href)) {
        link.setAttribute("rel", "nofollow");
      }

      if (href.includes("https://sportbet.one")) {
        const url = new URL(href, "https://sportbet.one");
        const pathSegments = url.pathname.split("/").filter(Boolean);

        if (
          (pathSegments.length > 0 && pathSegments[0] === currentLanguage) ||
          currentLanguage === "en"
        ) {
          return;
        }

        url.pathname = `/${currentLanguage}${url.pathname}`;
        link.setAttribute("href", url.href.replace("https://sportbet.one", ""));
      }
    });

    return doc.body.innerHTML;
  } catch (e) {
    return html;
  }
};
