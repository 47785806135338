import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAccountUser } from "selectors/loginSelectors";
import { getUserStats } from "selectors/userStatsSelectors";

import { ZECHAT_LOADED } from "actions/actionTypes.js";

import { isBrowser } from "utils/browserUtils";
import {
  getTags,
  setTags,
  zeIdentify,
  zeIsLoaded,
  zeUpdateSettings,
} from "utils/zeChatUtils.ts";

import { useAdvancedInterval } from "./useInterval.ts";

interface UserAccount {
  userId?: string;
  provider?: string;
  eosAccount?: string;
}

interface UserStatsData {
  title?: string;
  deposits?: {
    addresses?: Record<string, string>;
    totalCount?: number;
  };
  bonuses?: {
    completedCount: number;
  };
}

export const generateZendeskTags = (userAccount: UserAccount | null, userStatsData: UserStatsData | null) => {
  const tags = [];
  if (userAccount && !!userStatsData) {
    if (userAccount.userId) tags.push(`ID:${userAccount.userId}`);
    if (userAccount.provider) tags.push(`loginMethod:${userAccount.provider}`);
    if (userAccount.eosAccount) tags.push(`eosAccount:${userAccount.eosAccount}`);

    if (userAccount.eosAccount) {
      const { deposits, bonuses } = userStatsData ?? {};
      Object.entries(deposits?.addresses ?? {}).forEach(([key, value]) => {
        tags.push(`${key}:${value}`);
      });

      if (typeof deposits?.totalCount !== "undefined")
        tags.push(`deposits:${deposits?.totalCount}`);

      if (bonuses){
        tags.push(`bonusUnderReview:${bonuses?.completedCount > 0 ? "Yes" : "No"}`);
      }
    }
  }
  return tags;
};

const areTagsEqual = (tagsA: string[], tagsB: string[]) => {
  return JSON.stringify(tagsA.sort()) === JSON.stringify(tagsB.sort());
};

export const useSetupZendesk = () => {
  const dispatch = useDispatch();
  const userAccount = useSelector(getAccountUser);
  const userStats = useSelector(getUserStats);

  const [zeChatLoaded, setZeChatLoaded] = useState(isBrowser() && !!window.zE);
  const [zeChatTick, setZeChatTick] = useState(0);
  const [previousTags, setPreviousTags] = useState<Array<string>>([]);

  const openChat = useCallback(() => {
    try {
      if (!!window.zE && zeChatLoaded) window.zE("webWidget", "open");
    } catch (e) {}
  }, [zeChatLoaded]);

  const updateChat = useCallback(() => {
    if (zeChatLoaded) return;
    if (zeIsLoaded()) {
      setZeChatLoaded(true);
      dispatch({
        type: ZECHAT_LOADED,
      });
    } else {
      setZeChatTick((t) => t + 1);
    }
  }, [zeChatLoaded]);

  useAdvancedInterval({ callback: updateChat, delay: 100 });

  useEffect(() => {
    if (!zeChatLoaded) return;
    zeUpdateSettings();

    const currentTags = getTags() || [];
    const newTags = generateZendeskTags(userAccount, userStats?.data);

    if (!areTagsEqual(previousTags, newTags)) {
      try {
        if (currentTags.length) {
          window.zE("webWidget", "chat:removeTags", currentTags);
        }

        if (userAccount && !!userStats?.data) {
          zeIdentify(userStats.data?.title);
          if (newTags.length) {
            setTags(newTags as []);
          }
          setPreviousTags(newTags);
        }
      } catch (e) {}
    }
  }, [zeChatLoaded, userStats?.data, userStats?.error, userAccount, previousTags]);

  return {
    zeChatLoaded,
    openChat,
  };
};
