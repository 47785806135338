import {
  CHALLENGE_CLOSE,
  CHALLENGE_ERROR,
  CHALLENGE_LOADED,
  CHALLENGE_LOADING,
  CHALLENGE_OPEN,
  CHALLENGE_RESET,
  CHALLENGE_UPDATE,
} from "actions/actionTypes";

const initialState = {
  loading: false,
  open: false,
  steps: [],
  currentStep: null,
  error: null,
  status: null,
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case CHALLENGE_OPEN:
      return {
        ...state,
        open: true,
      };

    case CHALLENGE_CLOSE:
      return {
        ...state,
        open: false,
      };

    case CHALLENGE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CHALLENGE_LOADED:
      return {
        ...state,
        loading: false,
      };

    case CHALLENGE_ERROR:
      return {
        ...state,
        error: payload?.error,
      };

    case CHALLENGE_UPDATE:
      return {
        ...state,
        steps: payload?.steps,
        currentStep: payload?.currentStep,
        status: payload?.status,
        error: null,
      };

    case CHALLENGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
