import { Tokens } from "@sbetdev2/sbet-utils/constants";
import { ChangeEvent, FC, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { BalanceTabs, getWalletSections } from "containers/popups/Wallet/constants.ts";

import { getAccountCurrency, getAccountUser } from "selectors/loginSelectors";
import { getFiatCurrency, getViewFiat } from "selectors/uiSelectors";
import {
  getExchangeRates,
  getWalletBalances,
  getWalletCasinoBalances,
} from "selectors/walletSelectors";

import { currencies, currenciesOrderSocial } from "constants/currencies.ts";

import RadioTabs from "components/nav/RadioTabs.tsx";

import { convertToFiat } from "utils/currencyUtils";

import { formatOrderViaBonus, getTotalBalance } from "../../helpers.ts";
import { AssetsTotal } from "../AssetsTotal";
import { DepositGuide } from "../DepositGuide";
import { SearchInput } from "../SearchInput";
import styles from "./Overview.module.scss";
import { AssetView, ButtonsSection } from "./components";
import { getSortedByValue } from "./helpers.ts";

export const Overview: FC = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<string>(BalanceTabs.MAIN);
  const [searchValue, setSearchValue] = useState("");
  const currencyCode = useSelector(getAccountCurrency) as Tokens;
  const fiatView = useSelector(getViewFiat);
  const fiatCurrency = useSelector(getFiatCurrency);
  const exchangeRates = useSelector(getExchangeRates);
  const user = useSelector(getAccountUser);
  const casinoWallet = useSelector(getWalletCasinoBalances);
  const mainWallet = useSelector(getWalletBalances);

  const casinoBalances = casinoWallet?.data ?? {};
  const mainBalances = mainWallet?.data ?? {};
  const currentBalance = tab === BalanceTabs.MAIN ? mainBalances : casinoBalances;
  const currency = currencyCode ? currencies[currencyCode] : null;
  const fiat = fiatView && fiatCurrency;
  let order = formatOrderViaBonus(mainBalances, casinoBalances, currenciesOrderSocial);

  if (searchValue) {
    order = order.filter((x) => x.toLowerCase().includes(searchValue.toLowerCase()));
  }

  if (order && currentBalance && exchangeRates.cryptoUSDT) {
    order = getSortedByValue(order as Tokens[], currentBalance, exchangeRates.cryptoUSDT);
  }

  const totalBalance = getTotalBalance(
    currentBalance,
    currency,
    exchangeRates.cryptoUSDT
  );

  const rate = (currency && exchangeRates.cryptoUSDT?.[currency.name]) || 0;
  const amount = rate ? totalBalance / rate : 0;
  const walletSections = getWalletSections();
  const totalBalanceFiat =
    (totalBalance / exchangeRates.cryptoUSDT?.[Tokens.USDT]) *
    exchangeRates.fiat?.[fiatCurrency];

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target.value);

  return (
    <>
      <RadioTabs options={walletSections} onChange={setTab} checkedState={tab} />
      <AssetsTotal
        currency={currency}
        id={user?.userId ?? ""}
        amount={amount}
        fiatAmount={totalBalanceFiat}
        fiatCurrency={fiat}
      />

      <SearchInput
        autoFocus={isMobile ? false : true}
        value={searchValue}
        onChange={handleSearch}
        placeholder={t("wallet__search_currency")}
      />

      <ul className={styles.assets}>
        {order.length > 0 ? (
          order.map((x) => {
            const amount = currentBalance?.[x] || 0;
            return (
              <AssetView
                key={`asset-view-${x}`}
                currency={currencies[x as Tokens]}
                amount={amount}
                fiatAmount={convertToFiat({
                  amount,
                  fiat: fiatCurrency,
                  crypto: x,
                  rates: exchangeRates,
                })}
                fiatCurrency={fiat}
              />
            );
          })
        ) : (
          <p className={styles.message}>{t("wallet__nothing_found")}</p>
        )}
      </ul>

      <ButtonsSection tabs={walletSections.find(({ id }) => id === tab)} />
      <DepositGuide />
    </>
  );
};
