import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Resources } from "scripts/index.ts";

const ErrorPopup = ({
  title = Resources.common__error,
  errorMessage = null,
  children,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <Modal centered isOpen autoFocus toggle={onClose}>
      <ModalHeader toggle={onClose}>{t(title)}</ModalHeader>
      <ModalBody style={{ whiteSpace: "pre-wrap" }}>
        {errorMessage ? t(errorMessage) : children}
      </ModalBody>
      <ModalFooter>
        <Button
          className="rounded btn-secondary btn-submit"
          style={{ minWidth: 100 }}
          onClick={onClose}
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ErrorPopup;
