import withPage from "hoc/withPage";
import usePromoVisible from "hooks/usePromoVisible.ts";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Switch, useLocation, useParams } from "react-router";
import { Route } from "react-router-dom";

import { LazyPromoCards } from "containers/PromoGrid/index.ts";
import SliderContainer from "containers/Slider/SliderContainer.tsx";

import { getSupportedLanguages } from "selectors/locale.ts";

import { SliderType } from "constants/common.ts";
import { altCoins } from "constants/currencies.ts";
import { esportsNavItems } from "constants/sports";

import HomePage from "pages/HomePage";
import SportPage from "pages/SportPage/index.ts";

import SkeletonLoader from "components/common/Loaders/Skeleton.tsx";
import SuspensePostHydration from "components/common/Loaders/SuspensePostHydration.tsx";
import Only from "components/common/Only.tsx";
import EventTypePicker from "components/nav/EventTypePicker";

const FeaturedEvents = React.lazy(() =>
  import(/* webpackChunkName: "FeaturedEvents"*/ "containers/FeaturedEvents")
);

const LiveSportSelection = React.lazy(() =>
  import(
    /* webpackChunkName: "LiveSportSelection"*/ "containers/sports/LiveSportSelection"
  )
);
const PrematchSportSelection = React.lazy(() =>
  import(
    /* webpackChunkName: "PrematchSportSelection"*/ "containers/sports/PrematchSportSelection"
  )
);

const allowedGames = esportsNavItems.map((n) => n.name);

const getIsHomepage = (language, live, path, isSports) => {
  const homepagePath = [];
  if (language !== "en") homepagePath.push(language);
  if (live) homepagePath.push("live");
  if (isSports) homepagePath.push("sports");

  return path === "/" + homepagePath.join("/");
};

const SportHomePage = () => {
  const { i18n } = useTranslation();
  const { supportedLanguages } = useSelector(getSupportedLanguages);
  const prefix = `/:language(${supportedLanguages.join("|")})?`;
  const gamePrefix = `:game(${allowedGames.map((g) => `${g}-crypto-betting`).join("|")})`;
  const { type } = useParams();
  const { pathname } = useLocation();
  const showPromo = usePromoVisible();

  const live = type === "live";
  const isSports = type === "sports";
  const isHomepage = getIsHomepage(i18n.language, live, pathname, isSports);

  const sliderParams = useMemo(
    () => ({
      type: showPromo ? SliderType.Promotion : SliderType.Home,
      slideMaxHeight: showPromo ? 355 : 200,
    }),
    [showPromo]
  );

  return (
    <>
      <SliderContainer {...sliderParams} />
      {!showPromo && (
        <Only browser>
          <SuspensePostHydration fallback={<SkeletonLoader />}>
            <FeaturedEvents />
          </SuspensePostHydration>
        </Only>
      )}
      {showPromo && (
        <SuspensePostHydration fallback={<SkeletonLoader />}>
          <LazyPromoCards />
        </SuspensePostHydration>
      )}

      <EventTypePicker />

      <SuspensePostHydration fallback={<SkeletonLoader />}>
        <div className="d-flex justify-content-between align-items-top homepage-nav">
          {live ? (
            <LiveSportSelection homepage={isHomepage} />
          ) : (
            <PrematchSportSelection homepage={isHomepage} isSports={isSports} />
          )}
        </div>
      </SuspensePostHydration>
      <Switch>
        <Route
          path={[
            `${prefix}/promo/participate/:promoId/:action?/:code?`,
            `${prefix}/promo/participate2/:promoId/:action?/:code?`,
            `${prefix}/:type(sports|live)/:sport`,
            `${prefix}/:type(sports|live)/:sport/esports-games/:esportsGame`,
            `${prefix}/:type(sports|live)/:sport(esports?)/${gamePrefix}`,
            `${prefix}/:type(sports|live)/:sport(esports?)/${gamePrefix}/:league`,
            `${prefix}/:type(sports|live)/:sport/league/:leagueId`,
            `${prefix}/:type(sports|live)/:sport/:league`,
          ]}
          exact
          component={SportPage}
        />
        <Route
          path={`${prefix}/:type(sports|live)`}
          exact
          component={() => <HomePage live={live} />}
        />
        <Route
          path={[`${prefix}/`, `${prefix}/:altcoin(${altCoins.join("|")})`]}
          exact
          component={HomePage}
        />
      </Switch>
    </>
  );
};

export default withPage(SportHomePage);
