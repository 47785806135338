import cn from "classnames";
import { ChangeEvent, FC } from "react";

import styles from "./SearchInput.module.scss";
import { ReactComponent as CrossIcon } from "./icons/cross-icon.svg";
import { ReactComponent as SearchIcon } from "./icons/search-icon.svg";

export enum SearchInputStyles {
  LIGHT = "light",
  DARK = "dark",
}

type Props = {
  value?: string;
  variant?: SearchInputStyles;
  placeholder?: string;
  autoFocus?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const SearchInput: FC<Props> = ({
  value,
  onChange,
  autoFocus,
  placeholder,
  variant = SearchInputStyles.DARK,
}) => (
  <div
    className={cn(styles.container, {
      [styles.light]: variant === SearchInputStyles.LIGHT,
    })}
  >
    <SearchIcon className={styles.searchIcon} />
    <input
      autoFocus={autoFocus}
      className={styles.input}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
    />
    {value && (
      <CrossIcon
        onClick={() => {
          onChange({ target: { value: "" } } as ChangeEvent<HTMLInputElement>);
        }}
        className={styles.crossIcon}
      />
    )}
  </div>
);
