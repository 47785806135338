import cx from "classnames";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import Radio from "components/common/Radio.tsx";

type RadioOptionType = { id: string; label: string };

type RadioTabType = {
  checked: boolean;
  onChange: (id: string) => void;
} & RadioOptionType;

type RadioTabsType = {
  options: RadioOptionType[];
  onChange: (id: string) => void;
  checkedState?: string;
  defaultChecked?: string;
  className?: string;
};

const RadioTab: FC<RadioTabType> = ({ id, label, onChange, checked }) => (
  <li>
    <Radio
      id={id}
      label={label}
      checked={checked}
      className={cx({ active: checked })}
      onChange={() => {
        onChange(id);
      }}
    />
  </li>
);

const RadioTabs: FC<RadioTabsType> = ({
  options,
  onChange,
  checkedState,
  defaultChecked,
  className,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(defaultChecked);
  if (!Array.isArray(options) || !options.length) {
    return null;
  }

  return (
    <ul className={cx("radio-tabs", className)}>
      {options.map((x) => (
        <RadioTab
          id={x.id}
          key={`radio-tabs-${x.id}`}
          label={t(x.label)}
          checked={checkedState ? x.id === checkedState : x.id === checked}
          onChange={(id) => {
            !checkedState && setChecked(id);
            onChange?.(id);
          }}
        />
      ))}
    </ul>
  );
};

export default RadioTabs;
