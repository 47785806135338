export const baseUrls = {
  home: "/",
  prematchSports: "/sports",
  liveSports: "/live",
  casino: "/casino",
  promotion: "/promotion",
};

export const sportUrl = {
  1: "soccer",
  2: "baseball",
  3: "basketball",
  5: "boxing-mma",
  7: "esports",
  8: "hockey",
  9: "cricket",
  10: "football",
  11: "tennis",
  12: "afl",
  13: "rugby-union",
  14: "rugby-league",
  15: "volleyball",
  16: "handball",
  17: "table-tennis",
};

export const pageTypes = {
  eventPrematch: "eventPrematch",
  sportLive: "sportLive",
  casinoProvider: "casinoProvider",
  eventLive: "eventLive",
  home: "home",
  sportsHome: "sportsHome",
  casinoCategory: "casinoCategory",
  casino: "casino",
  leagueLive: "leagueLive",
  casinoGame: "casinoGame",
  sport: "sport",
  esportsGameGroup: "esportsGameGroup",
  static: "static",
  league: "league",
};
