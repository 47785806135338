import { FC } from "react";
import { useSelector } from "react-redux";

import { getHomepagePrematchView } from "selectors/homepageSelectors";

import GroupEvents from "components/sports/GroupEvents";

import { getPreSportUrl } from "utils/urlUtils.ts";

export const Prematch: FC<{ activeSport: string }> = ({ activeSport }) => {
  const prematchView = useSelector(getHomepagePrematchView);
  return (
    <>
      {prematchView.map((x) => (
        <GroupEvents
          key={x.name}
          live={false}
          startOpen={true}
          forceOpen={true}
          group={x}
          shortLeague={false}
          invertCountBadge={true}
          showAll={getPreSportUrl(activeSport)}
          showSportIcon
          topHighlight={false}
          hideBadge
          sportId={activeSport}
        />
      ))}
    </>
  );
};
