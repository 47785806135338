export const getExchangeRates = (state) => state.wallet.exchangeRates;
export const getExchangeRatesFiat = (state) => state.wallet.exchangeRates.fiat;
export const getExchangeRatesCrypto = (state) => state.wallet.exchangeRates.crypto;
export const cryptoRatesSelector = (state) => state.wallet.exchangeRates.cryptoUSDT;
export const getWalletActiveCurrency = (state) => state.wallet.currency;
export const getWalletActiveTab = (state) => state.wallet.tab;
export const getWalletBalances = (state) => state.wallet.balances;
export const getWalletCasinoBalances = (state) => state.wallet.casinoBalances;
export const getWalletLockedBalances = (state) => state.wallet.lockedBalances;
export const getWalletAddresses = (state) => state.wallet.addresses;
export const fiatDepositConfigStatusSelector = (state) => state.wallet.fiatDeposit.status;
export const fiatDepositTokensSelector = (state) => state.wallet.fiatDeposit.tokens;
export const fiatDepositMethodsSelector = (state) => state.wallet.fiatDeposit.methods;
export const fiatDepositCurrenciesSelector = (state) =>
  state.wallet.fiatDeposit.allowedCurrencies;
export const withdrawFeeSelector = (state) => state.wallet.withdraw.fee.amount;
export const withdrawFeeStatusSelector = (state) => state.wallet.withdraw.fee.status;
export const withdrawFeeErrorSelector = (state) => state.wallet.withdraw.fee.error;
