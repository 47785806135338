import React from "react";
import { useHistory, useLocation } from "react-router";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const Popup: React.FC<{
  children: React.ReactNode;
  title: React.ReactNode;
  className?: string;
  onClose?: () => void;
  pushHistory?: boolean;
  withClose?: boolean;
  bodyStyle?: React.CSSProperties;
}> = ({
  title,
  className,
  onClose,
  children,
  withClose = true,
  pushHistory = true,
  bodyStyle,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const handleClose = () => {
    pushHistory && history.push(pathname);

    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <Modal
      centered
      isOpen={true}
      toggle={handleClose}
      autoFocus={false}
      className={className}
    >
      <ModalHeader toggle={handleClose} close={withClose ? null : <></>}>
        {title}
      </ModalHeader>
      <ModalBody style={bodyStyle}>{children}</ModalBody>
    </Modal>
  );
};

export default Popup;
