import { FC } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import styles from "./DepositGuide.module.scss";
import { DEPOSIT_GUIDE_URL } from "./constants";
import Binance from "./icons/binance.svg";
import Coinbase from "./icons/coinbase.svg";
import CryptoCom from "./icons/crypto-com.svg";
import Gemini from "./icons/gemini.svg";
import Kraken from "./icons/kraken.svg";

export const DepositGuide: FC = () => {
  const { t } = useTranslation();

  const baseServices = [Binance, Coinbase, CryptoCom];
  const servicesOptions = isMobile ? baseServices : [...baseServices, Kraken, Gemini];

  const handleFollowGuide = () => {
    window.open(DEPOSIT_GUIDE_URL, "_blank");
  };

  return (
    <section className={styles.container}>
      <h6 className={styles.header}>{t("deposit__guide_need_help")}</h6>
      {!isMobile && <p className={styles.text}>{t("deposit__guide_message")}</p>}

      {servicesOptions.map((icon) => {
        return <img key={icon} src={icon} className={styles.icon} />;
      })}

      <Button
        onClick={handleFollowGuide}
        className="btn btn-type-1 btn-primary w-100 mt-3"
      >
        {t("deposit__guide_howto")}
      </Button>
    </section>
  );
};
