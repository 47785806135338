export enum FiatConfigStatuses {
  IDLE = "IDLE",
  FAIL = "FAIL",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
}

export enum WithdrawFeeStatuses {
  IDLE = "IDLE",
  FAIL = "FAIL",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
}
