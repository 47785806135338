import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";

import { WalletContext } from "containers/popups/Wallet/WalletContext.ts";
import { WalletTabs } from "containers/popups/Wallet/constants";

import styles from "./ButtonsSection.module.scss";

type Props = { tabs?: { buttons: { label: string; tab: WalletTabs; style: string }[] } };

export const ButtonsSection: FC<Props> = ({ tabs }) => {
  const { t } = useTranslation();
  const { setActiveTab } = useContext(WalletContext);
  return (
    <div className={styles.buttons}>
      {tabs?.buttons.map(({ label, tab, style }) => {
        return (
          <button className={style} onClick={() => setActiveTab?.(tab)} key={label}>
            {t(label)}
          </button>
        );
      })}
    </div>
  );
};
